import React, { useState } from "react";
import { TopBar } from "components";
import { Radio, Button, notification, Steps } from 'antd';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { setCurrentAppointment } from "../../redux/actions/userActions";
import "./style_Type.css";

const SelectedType = () => {
    const dispatch = useDispatch();
    const { Step } = Steps;
    const [radioVal, setRadioVal] = useState("");
    const [selectedCard, setSelectedCard] = useState("Ontario");
    const history = useHistory();

    const continuee = () => {
        console.log(radioVal);
        history.push("/problems");
        // if (radioVal !== "") {
        //     history.push("/problems");
        // } else {
        //     notification.error({
        //         message: 'Error',
        //         duration: 2,
        //         description:
        //             'Please Select Provider.',
        //         onClick: () => {
        //             console.log('Notification Clicked!');
        //         },
        //     });
        // }
    };
    const goToBack = () => {
        history.goBack()
    };

    const onChangeRadio = e => {
        console.log('radioBTN', e.target.value);
        setRadioVal(e.target.value)
        dispatch(setCurrentAppointment(e.target.value));
    };

    const cardClick = (selectedCard) => {
        setSelectedCard(selectedCard)
    };

    return (
        <div>
            <TopBar />
            <div className="box">
                <p className="pageInfo">Providers</p>
                <h5 className="h5New">Dashboard / Select Provider</h5>
            </div>

            <div className="content contentSelect">
                <div className="container-fluid">


                    <div className="row">
                        <div className="col-sm-0 col-md-1 col-xl-2"></div>
                        <div className="col-sm-12 col-md-10 col-xl-8">
                            <div className="stepDiv">
                                <Steps>
                                    <Step status="finish" title="Login" icon={<UserOutlined />} />
                                    <Step status="finish" title="Verification" icon={<SolutionOutlined />} />
                                    <Step status="finish" title="Pay" icon={<LoadingOutlined />} />
                                    <Step status="wait" title="Done" icon={<SmileOutlined />} />
                                </Steps>
                                <div className="divPayment">
                                    <h2 className="lblPayment">Select Region</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-0 col-md-1 col-xl-2"></div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row text-left">
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                                <div className="col-sm-10 col-md-10 col-xl-8">
                                    <div className="div_flex">
                                        <div className="div_inner_flex">
                                            <div onClick={() => cardClick('Ontario')} className={`${selectedCard === 'Ontario' ? "card_selected" : "card_static"}`}>
                                                <img src="Images/problems/ontario.png" alt="" className="productImg" />
                                            </div>
                                            <div className="doc-info-right text-center">
                                                <h4 className="p-0 m-0">Ontario</h4>
                                            </div>
                                        </div>
                                        <div className="div_inner_flex">
                                            <div className={`${selectedCard === 'Alberta' ? "card_selected" : "card_static"}`} onClick={() => cardClick('Alberta')}>
                                                <img src="Images/problems/Alberta.png" alt="" className="productImg" />
                                            </div>
                                            <div className="doc-info-right text-center">
                                                <h4 className="p-0 m-0">Alberta</h4>
                                            </div>
                                        </div>
                                        <div className="div_inner_flex">
                                            <div className={`${selectedCard === 'Manitoba' ? "card_selected" : "card_static"}`} onClick={() => cardClick('Manitoba')}>
                                                <img src="Images/problems/manitabo.png" alt="" className="productImg" />
                                            </div>
                                            <div className="doc-info-right text-center">
                                                <h4 className="p-0 m-0">Manitoba</h4>
                                            </div>
                                        </div>
                                        <div className="div_inner_flex">
                                            <div className={`${selectedCard === 'British Columbia' ? "card_selected" : "card_static"}`} onClick={() => cardClick('British Columbia')}>
                                                <img src="Images/problems/columbia.png" alt="" className="productImg" />
                                            </div>
                                            <div className="doc-info-right text-center">
                                                <h4 className="p-0 m-0">British Columbia</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="col-sm-0 col-md-3 col-lg-3 col-xl-2 p-0 m-0"></div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 p-0 m-0"></div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 p-0 m-0">
                    <div className="div_btn">
                        <Button type="default" className="btnBg1 mr-3" onClick={goToBack}  >
                            BACK
                        </Button>
                        <Button type="primary" className="btn" onClick={continuee}  >
                            Continue
                        </Button>
                    </div>
                </div>
                <div className="col-sm-0 col-md-1 col-lg-1 col-xl-2"></div>
                {/* <Footer /> */}
            </div>
        </div>
    );
};

export default SelectedType;