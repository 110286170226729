import { combineReducers } from "redux";
import { userReducer } from "./user";
import { AppConfig } from "./appConfig";
import {
  doctorListReducer,
  selectedDocter,
  selectedDocterProfile,
} from "./doctor";
import { curentAppointment } from "./currentAppointments";
import { meeting } from "./meeting";
import { chat } from "./chat";
import { connectRouter } from "connected-react-router";
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    userReducer,
    selectedDocter,
    doctorListReducer,
    selectedDocterProfile,
    curentAppointment,
    meeting,
    chat,
    AppConfig
  });

export default reducers;
