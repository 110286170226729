
import style from "./style.module.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import store from 'store'
import { Form, Input, notification } from 'antd';
import { UPDATED_OPEN_GET, UPDATED_OPEN_PUT } from "services/common.api";


const ForgotSecurityPin = () => {

  const location = useLocation();
  const [usedQuestion, setUsedQuestion] = useState([] as any)
  const { email } = location.state;
  const [form] = Form.useForm();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [userAnswers, setUserAnswers] = useState<{ [key: string]: string }>({});

  const accessToken = store.get("sub")

  useEffect(() => {
    console.log(email, "email")
    isSecure()
  }, [])

  const isSecure = async () => {
    const usedQuestion = await UPDATED_OPEN_GET(`user/securityquestion?cognitoid=${accessToken}`)

    console.log(usedQuestion, "usedQuestion usedQuestion")

    setUsedQuestion(usedQuestion?.data)
  }

  const onFinish = async (values: any) => {
    console.log(values, "values")
    // Verify answers against the fetched questions
    const isAnswersCorrect = usedQuestion.every((question) => {
      const userAnswer = userAnswers[question.id.toString()];
      return userAnswer && userAnswer === question.answer;
    });

    if (isAnswersCorrect) {
      // Answers are correct, proceed with further logic
      console.log('Success:', values);

      let data = {
        security_pin : values?.securitypin,
        cognitoid : accessToken
      }

      const updateSecuritypin = await UPDATED_OPEN_PUT(`user/security/updatpin`, data).then((res) => {
        setLoader(false)
        form.resetFields();
        history.push("/login");
        notification.success({
          message: 'Security pin updated successfully.',
        })
      });
    } else {
      notification.error({
        message: 'Security answer not match',
      })
      // Answers are incorrect, handle accordingly
      console.log('Incorrect answers');
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleInputChange = (questionId: number, value: string) => {
    setUserAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: value }));
  };


  return (

    <div className={style.div_container}>

      <div className={style.div_content}>
        <div className={style.div_image}>
          <div>
            {/* <p className="p_name">Welcome</p> */}
            <p className={style.p_name}>Forgot Security Pin ?</p>
          </div>
          <div className={style.div_round}>
            <img
              src="Images/logo.png"
              className={style.imgFluid}
              alt="Doccure Login"
            />
          </div>
        </div>

        <div className={style.div_form}>
          <>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <div className="form-group form-focus">
                {usedQuestion.map((question) => (
                  <Form.Item
                    key={question.id}
                    name={`${question.id}`}
                    rules={[{ required: true }]}
                    label={question.question}
                  >
                    <Input
                      size="large"
                      placeholder={`Enter answer for`}
                      onChange={(e) => handleInputChange(question.id, e.target.value)}
                    />
                  </Form.Item>
                ))}

                <Form.Item
                  key={'securitypin'}
                  name={`securitypin`}
                  rules={[{ required: true }]}
                  label={'Enter new security pin'}
                >
                  <Input
                    size="large"
                    placeholder={`Enter security pin`}
                  />
                </Form.Item>

                {!loader ? (
                  <button
                    className={`${style.btnDashboard} btn btn-primary btn-block mt-5`}
                  // htmlType="submit"
                  >
                    Continue
                  </button>
                ) : (
                  <button className="btn btn-primary btn-block" disabled>
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: 'white', width: 25, height: 25 }}
                    />
                  </button>
                )}
              </div>
            </Form>
          </>
        </div>
      </div>
      <div className={`text-center ${style.dontHave}`}>
        <Link to="/" style={{ color: "#1a6ab1" }}>
          {" "}
          Login
        </Link>
      </div>
      <div className={`text-center  ${style.copy}`}>© 2024 ONRx. All rights reserved.</div>
    </div>

  );
};

export default ForgotSecurityPin;
