import apiClient from "../axios";
import store from 'store'

export function startVirtualWaitingRoom(payload) {
  return apiClient
    .post(`/virtualwaitingroom/init`, payload, { headers: { Authorization: `Bearer ${store.get("auth_token_refresh")}` } })
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}

export function joinVirtualWaitingRoom(payload) {
  return apiClient
    .post(`/virtualwaitingroom/join`, payload, { headers: { Authorization: `Bearer ${store.get("auth_token_refresh")}` } })
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}

export function cancelVirtualWaitingRoom(payload) {
  return apiClient
    .post(`/virtualwaitingroom/cancel`, payload, { headers: { Authorization: `Bearer ${store.get("auth_token_refresh")}` } })
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}

export function leaveVirtualWaitingRoom(payload) {
  return apiClient
    .post(`/virtualwaitingroom/leave`, payload, { headers: { Authorization: `Bearer ${store.get("auth_token_refresh")}` } })
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}
