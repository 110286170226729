
import { TopBar } from "components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import style from './style.module.scss'
import { useEffect, useState } from "react";

const BookingSuccess = () => {
    const [DoctorDetail, setDoctorDetail] = useState({} as any);

    useEffect(() => {
        let detail = JSON.parse(localStorage.getItem("selectedDoctor"));
        setDoctorDetail(detail);
    }, []);

    return (
        <div>
            <TopBar />
            <div>
                <div className="content success-page-cont">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card success-card">
                                    <div className="card-body">
                                        <div className="success-cont">
                                            <i><FontAwesomeIcon icon={faCheck} /> </i>
                                            <h3>Appointment booked Successfully!</h3>
                                            <p>Appointment booked with <strong>Dr. {DoctorDetail?.firstname} {DoctorDetail?.lastname}</strong><br /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.footer}>
                {/* <Footer /> */}
            </div>
        </div>
    );
};

export default BookingSuccess;