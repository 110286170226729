import style from "./style.module.css";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCurrentAccount, authentication, setCognitoUserData, setUserToken, setUser, selectPaymentProvider, selectAppointmentType, bookAppointmentDoctor, selectedService, loadWhichVersion, callbackAppointment, setProblem, setMeetingEndData, setCallID, setEndModal } from "../../redux/actions/userActions";
import { Spinner } from "react-bootstrap";
import { Form, Input, Button, Checkbox, notification, Spin,Modal } from 'antd';
import * as AuthService from "../../services/auth";
import store from 'store'
// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import WebrtcCallHandler from "lib/WebrtcCallHandler";
import SSEHandler from "lib/SSEHandler";
import ReCAPTCHA from "react-google-recaptcha";
import { UPDATEDGET, UPDATEDPOST, UPDATED_OPEN_POST } from "../../services/common.api";

const currentConfig = Auth.configure();

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const Loginaccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [resendlnk, setresendlnk] = useState(false);
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();
  const [login, setLogin] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isCaptchaValid, setCaptchaValid] = useState(false)
  const [userAuthModal, setUserAuth] = useState(false);
  const [inputType, setInputType] = useState('')
  const user = useSelector((state: any) => state.userReducer);
  const [currentUser, setUserData] = useState({} as any)
  const search = window.location.search;
  const params = new URLSearchParams(search)
  const userToken = params.get('token')
  const accessToken = store.get("accessToken")

  useEffect(() => {
    console.log("user data : ", user)
    console.log("userToken : ", userToken)
    console.log("accessToken : ", accessToken)
    // setUserData(user.uer)
    if (accessToken) {

      console.log("user login che ahiya use effect ma",user?.user?.id)

      setLogin(true)
      onModalClick(user?.user?.id,'login')
    } else {
      setLogin(false)
      setVisible(false)
    }

    // logout();
    // loadCaptchaEnginge(6, 'white', 'black', 'upper');
    console.log(user);
  }, []);

  const handleSOk = () => {
    setUserAuth(false);
    // handleAllowAccess();
  };

  const handleCancel = () => {
    setVisible(false);
    // handleDenyAccess();
  };

  const handleOk = () => {
    console.log("join")

    const url = `https://dev613.onrx.ca?token=${currentUser?.token}`; // Replace with your desired URL    
    window.open(url, '_blank');
    // history.push('https://localhost:3000?token={meetingToken}')
    setVisible(false);
    // handleAllowAccess();
  };

  const logout = () => {
    AuthService.logout();
    store.clearAll();
    dispatch(authentication(false));
    WebrtcCallHandler.getInstance().cleanUp();
    SSEHandler.getInstance().cleanUp();
    dispatch(setUser([]));
    dispatch(selectPaymentProvider([]));
    dispatch(selectAppointmentType([]));
    dispatch(bookAppointmentDoctor([]));
    dispatch(selectedService([]));
    dispatch(loadWhichVersion([]));
    dispatch(callbackAppointment([]));
    dispatch(setProblem([]));
    dispatch(setMeetingEndData([]));
    dispatch(setCallID([]));
    dispatch(setEndModal(false));
  };


  const handleRecaptchaChange = (value) => {
    if (value != null && value != '') {
      setCaptchaValid(true)
    } else {
      setCaptchaValid(false)
    }
    console.log("Captcha value:", value);
  }

  const signIn = async (values: any) => {

    console.log(values, "values")

    if (isCaptchaValid == true) {
      setLoader(true);
      try {

        if (inputType == 'Mobile') {
          let name = values.email + "@onrx.ca"
          setEmail(name);
        } else {
          setEmail(values.email);
        }

        let data = {
          email: inputType == 'Mobile' ? values.email + "@onrx.ca" : values.email,
          password: values.password,
          withMobile: inputType == 'Mobile' ? true : false
        }
        const user = await UPDATEDPOST("user/login", data);
        console.log(user, "pppppp------0 ");

        store.set("email", values.email);
        store.set("tempPassword", values.password);

        if (user?.data?.err === 'callback.newPasswordRequired is not a function') {
          // dispatch(setCognitoUserData(user));
          history.push("/complete-new-password");
        } else if (user?.data?.err == 'Incorrect username or password.') {
          store.set('userauth', false);
          store.remove("tempPassword");
          setLoader(false);
          // form.resetFields(["captcha"]);
          // loadCaptchaEnginge(6, 'white', 'black', 'upper');
          notification.error({
            message: "Error",
            duration: 2,
            description: "Invalid Username or Password",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });

        } else if (user?.data?.err == 'User is not confirmed.') {
          store.set('userauth', false);
          store.remove("tempPassword");
          setLoader(false);
          setresendlnk(true);
          // form.resetFields(["captcha"]);
          // loadCaptchaEnginge(6, 'white', 'black', 'upper');
          notification.error({
            message: "Error",
            duration: 3,
            description: "Account was not verified, Please check your email and verify the link"
          })

        } else {
          store.set("accessToken", user?.data?.result?.accessToken?.jwtToken)
          store.set('auth_token_refresh', user?.data?.result?.idToken?.jwtToken);
          store.set('refresh_token', user?.data?.result?.refreshToken?.token);
          store.set('auth_token_exp', user?.data?.result?.idToken?.payload?.exp);
          await store.set('sub', user?.data?.result?.idToken?.payload?.sub)
          dispatch(loadCurrentAccount());
          dispatch(authentication(true));
          store.remove("tempPassword");
          setLogin(true)

          console.log(user?.data, "user data ahiya che ")
          if (user?.data?.result?.idToken?.payload?.sub) {

            const userdata = await UPDATEDGET(`user?cognitoid=${user?.data?.result?.idToken?.payload?.sub}`).then(async (userInfo) => {
              // setUserData(userInfo?.data?.body)
              console.log(userInfo, "userInfo")

              onModalClick(userInfo?.data?.body?.id,'user')

            })

            setLoader(false);
            // store.set('userauth', true);
            // // history.push("/dashboard");
            // setNoteModal(true);
          }
        }

        let name
        if (inputType == 'Mobile') {
          name = values.email + "@onrx.ca"
        } else {
          name = values.email
        }

        // For amplify login code start
        const userr = await Auth.signIn(name, values.password)
        console.log(user, "login with ampifyyfyfyfy")
        // For amplify login code end

      } catch (err) {
        console.log("error: ", err);
        store.set('userauth', false);
        setLoader(false);
      }
    }
    else {
      notification.error({
        message: "Error",
        duration: 2,
        description: "Captcha Does Not Check",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      // form.resetFields(["captcha"]);
    }
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };

  const validateMobileOrEmail = (rule, value, callback) => {
    const mobileRegex = /^\d{10}$/; // Regex pattern for a 10-digit mobile number
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex pattern for an email address


    if (value && !mobileRegex.test(value) && !emailRegex.test(value)) {
      callback('Please enter a valid mobile number or email address');
    } else {
      callback();
    }

    if (mobileRegex.test(value)) {
      console.log("mobile regex value")
      setInputType('Mobile');
    } else if (emailRegex.test(value)) {
      console.log("email regex value")
      setInputType('Email');
    } else {
      // setInputType('');
    }
  };


  const onModalClick = async (id,from) => {
    console.log(from,"from from from")
    console.log(accessToken, "accessToken accessToken", user)

    console.log("user login che", user.user)
    try {
      let body = {
        id: id,
        token: userToken
      }
      console.log(body, "body body")
      const res = await UPDATED_OPEN_POST(`meeting/token/validate`, body);
      // history.push(`https://localhost:3000?token=${res?.data?.token}`)

    //  window.open(`https://localhost:3000?token=${res?.data?.token}`, '_blank', 'noopener,noreferrer')

      // if (newWindow) newWindow.opener = null
      setUserData(res?.data)

      setVisible(true)
      console.log(res.data.token, "verify token success");
    } catch (err) {
      console.log("verify token error", err);
      setUserAuth(true)
    }

  };


  return (
    <>
      {login ? null : <>
        <Form
          form={form}
          name="basic"
          onFinish={signIn}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          validateMessages={validateMessages}
        >
          <div className={style.div_container}>
            <div className={style.div_content}>
              <div className={style.div_image}>
                <div>
                  {/* <p className="p_name">Welcome</p> */}
                  <p className={style.p_name}>Sign in to Continue in ONRx</p>
                </div>
                <div className={style.div_round}>
                  <img
                    src="Images/logo.png"
                    className={style.imgFluid}
                    alt="Doccure Login"
                  />
                </div>
              </div>

              <div className={style.div_form}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      // type: "email",

                      // message: "Please enter your email!",
                    },
                    { validator: validateMobileOrEmail }
                  ]}
                >
                  <Input size="large" placeholder="Email or Mobile" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password size="large" placeholder="Password" />
                </Form.Item>

                <div className="mb-3">
                  <ReCAPTCHA sitekey="6Lf2XYEmAAAAANA_AIl5HcdQfkAi1GSj2RCwXUK2" onChange={handleRecaptchaChange} />
                </div>

                {!loader ? (
                  <button
                    className={`${style.btnDashboard} btn btn-primary btn-block`}
                    type="submit"
                  >
                    Login
                  </button>
                ) : (
                  <button className="btn btn-primary btn-block" type="submit">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "white", width: 25, height: 25 }}
                    />
                  </button>
                )}

                <div className={`text-center  ${style.copy}`}>© 2024 ONRx. All rights reserved.</div>

              </div>
            </div>
          </div>
        </Form>
      </>}

      <Modal
        title="UNAUTHORIZED"
        visible={userAuthModal}
        // closable={false}
        footer={[
          // <Button key="deny" onClick={handleCancel}>
          //   Deny Access
          // </Button>,
          <Button key="allow" type="primary" onClick={handleSOk}>
            Ok
          </Button>
        ]}
      >
        <p>Unauthorized user access</p>
      </Modal>

        <Modal
          title="Join meeting?"
          visible={visible}
          // closable={false}
          footer={[
            <Button key="deny" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="allow" type="primary" onClick={handleOk}>
              Join meeting
            </Button>
          ]}
        >
          <p>Do you want to join meeting?</p>
        </Modal>
    </>
  );
};

export default Loginaccess;
