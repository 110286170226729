/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Drawer, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UserEventWorkshop from "./UserEventWorkshop";
import { UPDATEDGET, UPDATEDPOST } from "../../services/common.api";
import { TopBar } from "components";
import DashboardSidebar from "pages/dashboard/sidebar/sidebar";
import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { AppointmentRequestType, setSelectedFamilyMember } from "redux/actions/userActions";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../graphql/subscriptions';
import { MessageOutlined } from "@ant-design/icons";
import { RINGER } from "pages/dashboard/img";
import PageHeader from "components/pageHeader";
import Footer from "components/footerFix";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


const ViewEvents = () => {
  const { user } = useSelector((state) => state.userReducer);

  const [eventWorkShopList, setEventWorkShopList] = useState([]);
  const [allEventWorkShopList, setallEventWorkShopList] = useState([]);
  const [registeredEvent, setRegisteredEvent] = useState([]);
  const [wishlistEvent, setWishlistEvent] = useState([]);
  const [filterDate, setFilterDate] = useState();
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState(null)
  // const audio = new Audio('https://onrx-dev-portal.s3.ca-central-1.amazonaws.com/message.mp3');

  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log('YSYSYSYSYSY',userAgent);
    setUserAgent(userAgent);
    }, []);

  const playAudio = () => {
    const audio = new Audio();
    // audio.crossOrigin = "anonymous";
    audio.src = 'https://www.soundjay.com/phone/sounds/telephone-ring-01a.mp3';
    audio.play()
      .catch(error => {
        // Handle any playback errors
        console.log(error,"audio play error");
      });
  }

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }
  
  useEffect(() => {
    if(user?.id){
      const subscribeToNotifications = async () => {
        if (subscription && subscription._cleanup !== undefined) {
          // If a subscription already exists, no need to create a new one
          return;
        }
  
        const newSubscription = API.graphql(graphqlOperation(subscriptions.subscribeToNewNotification, { userId: user?.id + "" })).subscribe({
          next: (notificationData) => {
            const newNotification = notificationData.value.data.subscribeToNewNotification;
            console.log('New notification received:', newNotification);
  
            console.log(localStorage.getItem("chatHead"),"localStorage.getItem",newNotification?.conversationId);
            if(parseInt(newNotification?.conversationId) == parseInt(localStorage.getItem("chatHead"))){}else{
              notification.open({
                message: 'New Message',
                description: newNotification?.message,
                duration: 5, 
                icon: <MessageOutlined style={{ color: '#1890ff' }} />,
                placement: 'topRight',
              });
              // audio.play();
              playAudio()
            }
  
          },
          error: (error) => {
            console.log(user)
            console.error('Error subscribing to new notification:', error);
          },
        });
  
        setSubscription(newSubscription);
      };
  
      subscribeToNotifications();
  
      return () => {
        if (subscription) {
          subscription?.unsubscribe();
          setSubscription(null); 
        }
      };
    }
   
  }, [subscription,user?.id]); 

  const getAllWishlistedEvent = async () => {

    

    const eventData = await UPDATEDGET(`eventwishlists/${user.id}`);
    const {
      data: { body },
    } = eventData;

    let rawLogs = {
      "userIdentity": {
      "loggedinuser": `${user?.Email}`,
      "role":`${user?.role}`,
      "createdate":new Date().toISOString()
      },
      "action": "ACCESS",
      "source": " Workshop Page",
      "sourceIPAddress": "3.99.184.26",
      "userAgent": userAgent,
      "ipAddress": localStorage.getItem('ipAddress'),
      "apiendpoint": `eventwishlists/${user.id}`,
      "requestParameters": {
        
      },
      "responsestatus": `${eventData?.status}`,
      // "response": JSON.stringify(response?.data)
    } 


    let logBody = {
      // message: `User - ${user?.sub} - Fetch All Events From Wishlist- Workshop Page`,
      message: rawLogs,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Workshop Page`,
    }

    logEvents(logBody);

    if (body && body.length > 0) {
      // const workshops = body.filter((item) => {
      //   return item.event_type === "workshop";
      // });
      setWishlistEvent(body);
    } else {
      setWishlistEvent([]);
    }
  };

  const getAllRegisteredEvent = async () => {

    

    console.log("user.id: ", user.id);
    const eventData = await UPDATEDGET(`eventregistrations/${user.id}`);
    console.log("eventData: ", eventData);
    
    let rawLogs = {
      "userIdentity": {
      "loggedinuser": `${user?.Email}`,
      "role":`${user?.role}`,
      "createdate":new Date().toISOString()
      },
      "action": "ACCESS",
      "source": " Workshop Page",
      "sourceIPAddress": "3.99.184.26",
      "userAgent": userAgent,
      "ipAddress": localStorage.getItem('ipAddress'),
      "apiendpoint": `eventregistrations/${user.id}`,
      "requestParameters": {
        
      },
      "responsestatus": `${eventData?.status}`,
      // "response": JSON.stringify(response?.data)
    } 


    let logBody = {
      // message: `User - ${user?.sub} - Get All register Events - Workshop Page`,
      message: rawLogs,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Workshop Page`,
    }

    logEvents(logBody);

    const {
      data: { body },
    } = eventData;
    // const workshops =
    //   body &&
    //   body.length &&
    //   body.filter((item) => {
    //     return item.event_type === "workshop";
    //   });
    const workshops = body
    

    const wishlistData = await UPDATEDGET(`eventwishlists/${user.id}`);


    let rowLogs = {
      "userIdentity": {
      "loggedinuser": `${user?.Email}`,
      "role":`${user?.role}`,
      "createdate":new Date().toISOString()
      },
      "action": "ACCESS",
      "source": " Workshop Page",
      "sourceIPAddress": "3.99.184.26",
      "userAgent": userAgent,
      "ipAddress": localStorage.getItem('ipAddress'),
      "apiendpoint": `eventwishlists/${user.id}`,
      "requestParameters": {
        
      },
      "responsestatus": `${wishlistData?.status}`,
      // "response": JSON.stringify(response?.data)
  } 

  
  let logBody2 = {
    // message: `User - ${user?.sub} - Fetch Events From Wishlist- Workshop Page`,
    message: rowLogs,
    logGroupName: "ONRx-Patient-Portal",
    logStreamName: `User - ${user?.sub} - Workshop Page`,
  }

  logEvents(logBody2);

    // const filteredWishlistData = wishlistData.data.body.filter((item) => {
    //   return item.event_type === "workshop";
    // });
    const filteredWishlistData = wishlistData.data.body

    workshops &&
      workshops.forEach((workshop) => {
        filteredWishlistData &&
          filteredWishlistData.forEach((wishlist) => {
            if (workshop.id === wishlist.id) {
              workshop.registered = true;
            }
          });
      });
    setRegisteredEvent(workshops);
  };

  const getAllRecurranceEvemt = async () => {

    

    console.log("event and workshop");
    try {
      // const eventData = await GET(`event/recurrence`);
      // const eventData = await UPDATEDGET(`events/recurrence`);
      const eventData = await UPDATEDGET(`events`);
      console.log("eventData: ", eventData);

      let rowLogs = {
      
        "userIdentity": {
          "loggedinuser": `${user?.Email}`,
          "role":`${user?.role}`,
          "createdate":new Date().toISOString()
          },
          "action": "ACCESS",
          "source": " Workshop Page",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `eventwishlists/${user.id}`,
          "requestParameters": {
            
          },
          "responsestatus": `${eventData?.status}`,
          // "response": JSON.stringify(response?.data)
      } 
  
  
      let logBody1 = {
        // message: `User - ${user?.sub} - Fetch All Events- Workshop Page`,
        message: rowLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${user?.sub} - Workshop Page`,
      }
  
      logEvents(logBody1);

      const {
        data: { body },
      } = eventData;

      // const workshops =
      //   body.length &&
      //   body.filter((item) => {
      //     return item.event_type === "workshop";
      //   });
      

      const workshops = body

      const registeredEvent = await UPDATEDGET(`eventregistrations/${user.id}`);

      let rawLogs = {
        "userIdentity": {
        "loggedinuser": `${user?.Email}`,
        "role":`${user?.role}`,
        "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": " Workshop Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `eventregistrations/${user.id}`,
        "requestParameters": {
          
        },
        "responsestatus": `${registeredEvent?.status}`,
        // "response": JSON.stringify(response?.data)
    } 
    let logBody = {
      // message: `User - ${user?.sub} - Get All register Events - Workshop Page`,
      message: rawLogs,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Workshop Page`,
    }

    logEvents(logBody);

      // const filteredworkshop =
      //   registeredEvent &&
      //   registeredEvent?.data?.body?.length &&
      //   registeredEvent?.data?.body?.filter((item) => {
      //     return item.event_type === "workshop";
      //   });
      const filteredworkshop =
        registeredEvent &&
        registeredEvent?.data?.body

      workshops.forEach((workshop) => {
        filteredworkshop &&
          filteredworkshop.length &&
          filteredworkshop.forEach((filtered) => {
            if (workshop.id == filtered.id) {
              console.log("sdskdhbj");
              workshop.isRegistered = true;
            }
          });
      });
      console.log("workshops", workshops);

      setEventWorkShopList(workshops);
      setallEventWorkShopList(workshops);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    getAllRecurranceEvemt();
  }, []);

  const { TabPane } = Tabs;
  function callback(key) {
    if (key == 1) {
      getAllRecurranceEvemt();
    }
    if (key == 2) {
      getAllRegisteredEvent();
    }
    if (key == 3) {
      getAllWishlistedEvent();
    }
  }

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    // <>Hello</>
    <div  style={{height:'100vh'}}>
      <TopBar onShow={showDrawer} />
      

      <div className="content" style={{ height: '100%' }} > 
        <div className="container-fluid" style={{ height: '100%' }}  >
          <div className="row" style={{ height: '100%' }}>
            <div
              className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar `}
              >
              <DashboardSidebar />
            </div>

            <Drawer
              closable={false}
              width={300}
              placement="left"
              onClose={onClose}
              visible={visible}
              style={{ paddingTop: 0 }}
            >
              <div className="widget-profile pro-widget-content pt-0 pb-4">
                <div className="profile-info-widget">
                  <img src="../Images/logo.png" height="50" alt="" />
                </div>
              </div>
              <DashboardSidebar />
            </Drawer>
            <div className="col-md-7 col-lg-8 col-xl-10"  >
            <PageHeader
                  items={[
                    { label: "Dashboard", link: "/dashboard" },
                    { label: "Event", link: "" },
                  ]}
                  pageTitle="Event"
                />
              <Tabs className="mb-4" defaultActiveKey="1" onChange={callback}>
                <TabPane tab="All" key="1">
                  <Row>
                    <Col span={24}>
                      <UserEventWorkshop
                        eventType="all"
                        requiredEvent={eventWorkShopList}
                        getAllEventAndWorkShops={getAllRecurranceEvemt}
                        setFilterDate={setFilterDate}
                        getAllRegisteredEvent={getAllRegisteredEvent}
                        filterDate={filterDate}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Registered" key="2">
                  <Row>
                    <Col span={24}>
                      <UserEventWorkshop
                        eventType="registered"
                        requiredEvent={registeredEvent}
                        getAllEventAndWorkShops={getAllRecurranceEvemt}
                        getAllRegisteredEvent={getAllRegisteredEvent}
                        getAllWishlistedEvent={getAllWishlistedEvent}
                        setFilterDate={setFilterDate}
                        filterDate={filterDate}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tab="Wishlist" key="3">
                  <Row>
                    <Col span={24}>
                      <UserEventWorkshop
                        eventType="wishlist"
                        requiredEvent={wishlistEvent}
                        getAllEventAndWorkShops={getAllRecurranceEvemt}
                        getAllWishlistedEvent={getAllWishlistedEvent}
                        setFilterDate={setFilterDate}
                        filterDate={filterDate}
                      />
                    </Col>
                  </Row>
                </TabPane> */}
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
              <Footer/>
            </div>
    </div>
  );
};
export default ViewEvents;