import { useEffect, useState } from "react";
import { TopBar } from "components";
import Footer from "components/footer";
import { Steps, Form, Button, Input } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  callbackAppointment,
  selectAppointmentType,
} from "../../redux/actions/userActions";
import style from "./style.module.css";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import {
  faBell,
  faCalendar,
  faCalendarAlt,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "components/pageHeader";
import { UPDATEDPOST } from "services/common.api";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


const SelectCallback = () => {
  const dispatch = useDispatch();
  const { Step } = Steps;
  const [selectedCard, setSelectedCard] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [tomorrowDate, setTomorrowDate] = useState<any>();
  const [todayAppTime, setTodayAppTime] = useState<any>();
  const [tommorowAppTime, setTommorowAppTime] = useState<any>();
  const [certainHours, setCertainHours] = useState("");
  const [reason, setReason] = useState("");
  const [Comments, setComments] = useState("");
  const [slots, setSlots] = useState(false);
  const [slotsCard, setSlotsCard] = useState(false);
  const [slotsTime, setSlotsTime] = useState("");
  const [isToday, setIsToday] = useState(false);
  const [slotsToShowTime, setSlotsToShow] = useState([]);
  const [addText, setAddText] = useState(false)
  const [vitalTextValue, setVitalTextValue] = useState("")
  const history = useHistory();

  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log('YSYSYSYSYSY',userAgent);
    setUserAgent(userAgent);
    }, []);

  const user = useSelector((state: any) => state.userReducer.user);
  console.log("USer---->", user);
  console.log("vitalTextValue---->", vitalTextValue);


  let slotIntervals: any = [
    // { id: '8:00AM-10:00AM', label: '8:00AM-10:00AM', imageSrc: 'Images/calendar-icon-png.png' },
    {
      id: "10:00AM-12:00PM",
      label: "10:00AM-12:00PM",
      imageSrc: "Images/purple-calender.png",
    },
    {
      id: "12:00PM-2:00PM",
      label: "12:00PM-2:00PM",
      imageSrc: "Images/purple-calender.png",
    },
    {
      id: "2:00PM-4:00PM",
      label: "2:00PM-4:00PM",
      imageSrc: "Images/purple-calender.png",
    },
    {
      id: "4:00PM-6:00PM",
      label: "4:00PM-6:00PM",
      imageSrc: "Images/purple-calender.png",
    },
    // { id: '6:00PM-8:00PM', label: '6:00PM-8:00PM', imageSrc: 'Images/calendar-icon-png.png' },
  ];

  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  const cardClick = (selectedCard, day) => {

    let rawLogs = {
      "userIdentity": {
        "loggedinuser": `${user?.Email}`,
        "role":`${user?.role}`,
        "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": " View Event Work Shops Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `eventwishlists/${user.id}`,
        "requestParameters": {
          
        },
        // "responsestatus": `${eventData?.status}`,
        // "response": JSON.stringify(response?.data)
    }


    let logBody = {
      // message: `User - ${user?.sub} - Select Slot Interval - Select Callback Page`,
      message: rawLogs,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Select Callback Page`,
    }

    logEvents(logBody);

    if (day == "today") {
      setIsToday(true);
      // setTodayDate(moment().format("dddd, MMM DD, YYYY"))
    } else {
      setIsToday(false);
      // setTodayDate(moment(1,'days').format("dddd, MMM DD, YYYY"))
    }
    console.log(selectedCard, "id123");
    setSlotsTime(selectedCard);
    setSelectedCard(selectedCard);
    setSlotsCard(true);

    console.log(todayDate, "TODAYYYSSSS");

    // dispatch(callbackAppointment(selectedCard));
    // if (selectedCard == "Morning") {
    //     setSlotsTime("08:00AM-12:00PM")
    // } else if (selectedCard == "Afternoon") {
    //     setSlotsTime("12:00PM-05:00PM")
    // } else if (selectedCard == "Evening") {
    //     setSlotsTime("05:00PM-09:00PM")
    // }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('Change:', e.target.value);
    setVitalTextValue(e.target.value)
    setAddText(true)
  };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {
    setTodayDate(moment().format("dddd, MMM DD, YYYY"));
    const today = moment();
    setTodayAppTime(today.format("HH:mm:ss"))

    setTomorrowDate(moment().add(1, "days").format("dddd, MMM DD, YYYY"));
    const tomorrow = moment().add(1, "days");
    setTommorowAppTime(tomorrow.format("HH:mm:ss"))

    dispatch(callbackAppointment(selectedCard));
    generateGreetings();


    console.log(todayAppTime, "TODAYYYY");
    console.log(tommorowAppTime, "TOMORROWWWW");
    console.log(isToday, "isTodayisTodayisToday");


    console.log(selectedCard, "selectedCard", slots);
  }, []);

  console.log(todayDate, "todayDate123");
  console.log(tomorrowDate, "tomorrowDate");

  const generateGreetings = () => {

    let currentHour: any = moment().format("HH");
    // let currentHour: any = 23;

    let currentTime: any = moment().format("HH:mmA");
    // let currentTime = '11:00M';

    let slotsToShow = slotIntervals.filter((slot) => {
      let slotStartTime: any = moment(slot.id.split("-")[0], "h:mmA").format(
        "HH:mmA"
      );

      // if (
      //   moment(slotStartTime, "HH:mmA").isSameOrAfter(
      //     moment(currentTime, "HH:mmA").add(1, "hours")
      //   )
      // ) {
      //   return slot;
      // }

      if (
        moment(slotStartTime, "HH:mmA").isSameOrAfter(
          moment(currentTime, "HH:mmA")
        )
      ) {
        return slot;
      }
    });
    // let slotsToShow = slotIntervals.filter(slot => {
    //     // Split slot.id into date and time parts
    //     let slotParts = slot.id.split(' ');

    //     // Extract the time portion and format it as "HH:mmA"
    //     let slotTime = moment(slotParts[1], 'HH:mm:ss').format("HH:mmA");

    //     // Combine the date and formatted time
    //     let slotStartTime = moment(`${slotParts[0]} ${slotTime}`, 'YYYY-MM-DD HH:mmA');

    //     if (moment(slotStartTime).isSameOrAfter(moment(currentTime, "HH:mmA").add(3, 'hours'))) {
    //         return slot;
    //     }
    // });

    if (slotsToShow.length == 0) {
      setSlotsCard(false);
    }


    console.log(slotsToShow.length, "----", "slotsToShow");

    setSlotsToShow(slotsToShow);

    if (currentHour >= 8 && currentHour < 10) {
      setCertainHours("Morning");
      console.log(currentHour, "event****");
      setSlots(false);
      setSlotsTime("8:00AM-10:00AM");
    } else if (currentHour >= 10 && currentHour < 12) {
      setCertainHours("Morning");
      console.log(currentHour, "event****");
      setSlots(false);
      setSlotsTime("10:00AM-12:00PM");
    } else if (currentHour >= 12 && currentHour < 14) {
      setCertainHours("Afternoon");
      // setSelectedCard('Afternoon');
      setSlotsTime("12:00PM-2:00PM");
      console.log(currentHour, "event****");
      setSlots(false);
    } else if (currentHour >= 14 && currentHour < 16) {
      setCertainHours("Afternoon");
      // setSelectedCard('Afternoon');
      setSlotsTime("2:00PM-4:00PM");
      console.log(currentHour, "event****");
      setSlots(false);
    } else if (currentHour >= 16 && currentHour < 18) {
      setCertainHours("Evening");
      console.log(currentHour, "event****");
      // setSelectedCard('Evening');
      setSlotsTime("4:00PM-6:00PM");
      setSlots(false);
    } else if (currentHour >= 18 && currentHour < 20) {
      setCertainHours("Evening");
      console.log(currentHour, "event**** 6");
      // setSelectedCard('Evening');
      setSlotsTime("6:00PM-8:00PM");
      setSlots(false);
    } else {
      setSlots(true);
      console.log("NO slots available");
    }
  };

  // const leftArraow = () => {
  //     setSlotsCard(false)
  //     let C = moment().format("dddd, MMM DD, YYYY")
  //     setTodayDate(moment(C).format("dddd, MMM DD, YYYY"))
  //     generateGreetings()
  // }
  // const rightArraow = () => {
  //     let C = moment().format("DD-MM-YYYY")
  //     let A = moment(C, "DD-MM-YYYY").add(1, 'days');
  //     console.log(moment(A).format("dddd, MMM DD, YYYY"));
  //     setTodayDate(moment(A).format("dddd, MMM DD, YYYY"))
  //     setSlots(false)
  // }

  return (
    <div style={{ height: "100%" }}>
      <TopBar />
      <div className="content contentSelect" >
        <div className="container-fluid">
          <PageHeader
            items={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Callback", link: "" },
            ]}
            pageTitle="Callback"
          />
          {/* <div className="row">
                        <div className="col-sm-0 col-md-1 col-xl-2"></div>
                        <div className="col-sm-12 col-md-10 col-xl-8">
                            <div className="stepDiv">
                                
                                <div className={style.divPayment}>
                                   
                                    <h2 className="lblPayment px-3">{todayDate}</h2>
                                    
                                </div>
                                <div className={style.divPayment}>
                                    
                                    <h2 className="lblPayment px-3">{tomorrowDate}</h2>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-0 col-md-1 col-xl-2"></div>
                    </div> */}

          {/* <div className="row mt-4 ">
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-2"></div>
            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8">
              <div className="stepDiv">
                <Steps size="small" className="custome-step">
                  <Step status="finish" title="Select Type" />
                  <Step status="finish" title="Appointment" />
                  <Step status="wait" title="Confirm" />
                </Steps>
              </div>
            </div>
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-2"></div>
          </div> */}
          <div className="row mt-5">
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10">

              {/* <h4><strong>Select Appointment</strong> </h4>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <h4><strong>October 2023</strong></h4>
                <FontAwesomeIcon icon={faArrowDown} />

                </div>     
              </div> */}

              <h2 className="lblPayment px-3 d-flex justify-content-center">
                {todayDate}
              </h2>

              <div className={style.divPayment}>
                {slotsToShowTime.length === 0 &&
                  todayDate === moment().format("dddd, MMM DD, YYYY") ? (
                  <div className={style.div_inner_flex}>
                    <div className={style.card_static}>
                      <img
                        src="Images/problems/recorsss.webp"
                        alt=""
                        className={style.productImg}
                      />
                      <div className="doc-info-center text-center">
                        <h4 className="p-0 m-0 pt-2" style={{ color: "red" }}>
                          No slots available
                        </h4>
                      </div>
                    </div>
                  </div>
                ) : (
                  // <>
                  //   {currentHour > 9 && currentHour < 17 ?
                  //     slotsToShowTime.map((slot) => (
                  //       <div className={style.div_inner_flex} key={slot.id}>
                  //         <div
                  //           className={`${slotsTime === slot.id && isToday == true
                  //               ? `${style.card_selected}`
                  //               : `${style.card_static}`
                  //             }`}
                  //           onClick={() => cardClick(slot.id, "today")}
                  //         >
                  //           <div className="doc-info-center text-center">
                  //             <h4 className="p-0 m-0">
                  //               <span
                  //                 style={{ fontWeight: "bold", color: "var(--primary-color)" }}
                  //               >
                  //                 Book
                  //               </span>
                  //             </h4>
                  //           </div>
                  //           <img
                  //             src={slot.imageSrc}
                  //             alt=""
                  //             className={style.productImg}
                  //           />
                  //           <div className="doc-info-center text-center">
                  //             <h4
                  //               className="p-0 m-0"
                  //               style={{ fontWeight: "bold", color: "var(--primary-color)" }}
                  //             >
                  //               {slot.label}
                  //             </h4>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     ))
                  //     : <div className={style.div_inner_flex}>
                  //       <div className={style.card_static}>
                  //         <img
                  //           src="Images/problems/recorsss.webp"
                  //           alt=""
                  //           className={style.productImg}
                  //         />
                  //         <div className="doc-info-center text-center">
                  //           <h4 className="p-0 m-0 pt-2" style={{ color: "red" }}>
                  //             No slots available
                  //           </h4>
                  //         </div>
                  //       </div>
                  //     </div>}
                  // </>

                  <>
                  {
                    slotsToShowTime.map((slot) => (
                      <div className={style.div_inner_flex} key={slot.id}>
                        <div
                          className={`${slotsTime === slot.id && isToday == true
                              ? `${style.card_selected}`
                              : `${style.card_static}`
                            }`}
                          onClick={() => cardClick(slot.id, "today")}
                        >
                          <div className="doc-info-center text-center">
                            <h4 className="p-0 m-0">
                              <span
                                style={{ fontWeight: "bold", color: "var(--primary-color)" }}
                              >
                                Book
                              </span>
                            </h4>
                          </div>
                          <img
                            src={slot.imageSrc}
                            alt=""
                            className={style.productImg}
                          />
                          <div className="doc-info-center text-center">
                            <h4
                              className="p-0 m-0"
                              style={{ fontWeight: "bold", color: "var(--primary-color)" }}
                            >
                              {slot.label}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))
                    }
                </>



                )}
                {/* {todayDate === moment().format('dddd, MMM DD, YYYY') && slotsToShowTime.map(slot => (
                                        <div className={style.div_inner_flex} key={slot.id}>
                                            <div
                                                className={`${slotsTime === slot.id ? `${style.card_selected}` : `${style.card_static}`}`}
                                                onClick={() => cardClick(slot.id)}
                                            >
                                                <div className="doc-info-center text-center ">
                                                    <h4 className="p-0 m-0"><span style={{ fontWeight: "bold" }}>Book</span></h4>
                                                </div>

                                                <img src={slot.imageSrc} alt="" className={style.productImg} />
                                                <div className="doc-info-center text-center">
                                                    <h4 className="p-0 m-0" style={{ fontWeight: "bold" }}>{slot.label}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
              </div>

              <h2 className="lblPayment px-3 d-flex justify-content-center">
                {tomorrowDate}
              </h2>
              <div className={style.divPayment}>
                {/* {
                                                todayDate !== moment().format('dddd, MMM DD, YYYY') ?
                                                    <img onClick={leftArraow} src="Images/problems/arrow-left.png" alt="" className={style.arrowIcon} /> :
                                                    null
                                            } */}

                {/* {
                                                todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                    <img onClick={rightArraow} src="Images/problems/arrow-right.png" alt="" className={style.arrowIcon} /> :
                                                    null
                                            } */}
                {slotsToShowTime.length === 0 &&
                  todayDate !== moment().format("dddd, MMM DD, YYYY") ? (
                  <div className={style.div_inner_flex}>
                    <div className={style.card_static}>
                      <img
                        src="Images/problems/recorsss.webp"
                        alt=""
                        className={style.productImg}
                      />
                      <div className="doc-info-center text-center">
                        <h4 className="p-0 m-0 pt-2" style={{ color: "red" }}>
                          No slots available
                        </h4>
                      </div>
                    </div>
                  </div>
                ) : (
                  // <>
                  //   {currentHour > 9 && currentHour < 17 ?
                  //     slotIntervals.map((slot) => (
                  //       <div className={style.div_inner_flex} key={slot.id}>
                  //         <div
                  //           className={`${slotsTime === slot.id && isToday == false
                  //               ? `${style.card_selected}`
                  //               : `${style.card_static}`
                  //             }`}
                  //           onClick={() => cardClick(slot.id, "tomorrow")}
                  //         >
                  //           <div className="doc-info-center text-center ">
                  //             <h4 className="p-0 m-0">
                  //               <span
                  //                 style={{ fontWeight: "bold", color: "var(--primary-color)" }}
                  //               >
                  //                 Book{" "}
                  //               </span>
                  //             </h4>
                  //           </div>

                  //           <img
                  //             src={slot.imageSrc}
                  //             alt=""
                  //             className={style.productImg}
                  //           />
                  //           <div className="doc-info-center text-center">
                  //             <h4
                  //               className="p-0 m-0"
                  //               style={{ fontWeight: "bold", color: "var(--primary-color)" }}
                  //             >
                  //               {slot.label}
                  //             </h4>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     ))
                  //     : <div className={style.div_inner_flex}>
                  //       <div className={style.card_static}>
                  //         <img
                  //           src="Images/problems/recorsss.webp"
                  //           alt=""
                  //           className={style.productImg}
                  //         />
                  //         <div className="doc-info-center text-center">
                  //           <h4 className="p-0 m-0 pt-2" style={{ color: "red" }}>
                  //             No slots available
                  //           </h4>
                  //         </div>
                  //       </div>
                  //     </div>}
                  // </>

                  <>
                  {
                    slotIntervals.map((slot) => (
                      <div className={style.div_inner_flex} key={slot.id}>
                        <div
                          className={`${slotsTime === slot.id && isToday == false
                              ? `${style.card_selected}`
                              : `${style.card_static}`
                            }`}
                          onClick={() => cardClick(slot.id, "tomorrow")}
                        >
                          <div className="doc-info-center text-center ">
                            <h4 className="p-0 m-0">
                              <span
                                style={{ fontWeight: "bold", color: "var(--primary-color)" }}
                              >
                                Book{" "}
                              </span>
                            </h4>
                          </div>

                          <img
                            src={slot.imageSrc}
                            alt=""
                            className={style.productImg}
                          />
                          <div className="doc-info-center text-center">
                            <h4
                              className="p-0 m-0"
                              style={{ fontWeight: "bold", color: "var(--primary-color)" }}
                            >
                              {slot.label}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))
                   }
                </>

                )}

                {/* {tomorrowDate !== moment().format('dddd, MMM DD, YYYY') && slotIntervals.map(slot => (
                                        <div className={style.div_inner_flex} key={slot.id}>
                                            <div
                                                className={`${slotsTime === slot.id ? `${style.card_selected}` : `${style.card_static}`}`}
                                                onClick={() => cardClick(slot.id)}
                                            >
                                                <div className="doc-info-center text-center ">
                                                    <h4 className="p-0 m-0"><span style={{ fontWeight: "bold" }}>Book</span></h4>
                                                </div>

                                                <img src={slot.imageSrc} alt="" className={style.productImg} />
                                                <div className="doc-info-center text-center">
                                                    <h4 className="p-0 m-0" style={{ fontWeight: "bold" }}>{slot.label}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
              </div>
            </div>
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
          </div>

          <div className="row pb-5 mt-5">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-1 col-md-1 col-xl-1"></div>

                <div className="col-sm-10 col-md-10 col-xl-10">
                <div className={style.div_flex}>
                <h4 className="card-title" style={{width: '50%'}} >Vitals Notes *</h4>
                </div>                    
                  <div className={style.div_flex}>

              {/* <div> */}
              <TextArea
                    showCount
                    maxLength={100}
                    onChange={onChange}
                    placeholder="Add Vitals"
                    style={{ height: 120, resize: 'none', width: "50%", borderRadius: "50%" }}
                  />
              {/* </div> */}
                 


                  </div>
                </div>
                <div className="col-sm-1 col-md-1 col-xl-2"></div>
              </div>
            </div>
          </div>

          {/* <div className="row">
                        <div className="col-12">
                            <div className="row text-left">
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                                <div className="col-sm-10 col-md-10 col-xl-8">
                                    <div className={style.div_flex}>
                                        {
                                            certainHours == 'Morning' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div onClick={() => cardClick('Morning')} className={`${selectedCard === 'Morning' ? `${style.card_selected}` : `${style.card_static}`}`}>
                                                            <img src="Images/morning.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Morning</h4>
                                                                <h6 className="p-0 m-0">8AM - 12PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            todayDate !== moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div onClick={() => cardClick('Morning')} className={`${selectedCard === 'Morning' ? `${style.card_selected}` : `${style.card_static}`}`}>
                                                            <img src="Images/morning.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Morning</h4>
                                                                <h6 className="p-0 m-0">8AM - 12PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            certainHours == 'Afternoon' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        {
                                            certainHours == 'Evening' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            slots == true ?
                                                <div className={style.div_inner_flex}>
                                                    <div className={style.card_static}>
                                                        <img src="Images/problems/cancel.png" alt="" className={style.productImg} />
                                                        <div className="doc-info-center text-center">
                                                            <h4 className="p-0 m-0 pt-2">No slots available</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                            </div>
                        </div>
                    </div> */}

          <div className="row pb-5 mt-5">
            <div className="col-12">
              <div className="row text-left">
                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                <div className="col-sm-10 col-md-10 col-xl-8">
                  <div className={style.div_flex}>
                    {/* {!slots && todayDate === moment().format('dddd, MMM DD, YYYY') && slotsToShowTime.map(slot => (
                                            <div className={style.div_inner_flex} key={slot.id}>
                                                <div
                                                    className={`${slotsTime === slot.id ? `${style.card_selected}` : `${style.card_static}`}`}
                                                    onClick={() => cardClick(slot.id)}
                                                >
                                                     <div className="doc-info-center text-center ">
                                                        <h4 className="p-0 m-0"><span style={{fontWeight: "bold"}}>Book</span></h4>
                                                    </div>

                                                    <img src={slot.imageSrc} alt="" className={style.productImg} />
                                                    <div className="doc-info-center text-center">
                                                        <h4 className="p-0 m-0"style={{fontWeight: "bold"}}>{slot.label}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))} */}
                    {/* {(slots == true || slotsToShowTime.length == 0) && todayDate == moment().format('dddd, MMM DD, YYYY') && (

                                            <div className={style.div_inner_flex} >
                                                <div className={style.card_static}>
                                                    <img src="Images/problems/images.jpeg" alt="" className={style.productImg} />
                                                    <div className="doc-info-center text-center">
                                                        <h4 className="p-0 m-0 pt-2" style={{ color: 'red' }}>No slots available</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}

                    {/* {todayDate !== moment().format('dddd, MMM DD, YYYY') && slotIntervals.map(slot => (
                                            <div className={style.div_inner_flex} key={slot.id}>
                                                <div
                                                    className={`${slotsTime === slot.id ? `${style.card_selected}` : `${style.card_static}`}`}
                                                    onClick={() => cardClick(slot.id)}
                                                >
                                                    <div className="doc-info-center text-center ">
                                                        <h4 className="p-0 m-0"><span style={{fontWeight: "bold"}}>Book</span></h4>
                                                    </div>

                                                    <img src={slot.imageSrc} alt="" className={style.productImg} />
                                                    <div className="doc-info-center text-center">
                                                        <h4 className="p-0 m-0"style={{fontWeight: "bold"}}>{slot.label}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))} */}
                  </div>
                </div>
                <div className="col-sm-1 col-md-1 col-xl-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <Footer
          // location="searchDoctor"
          pageName="Submit"
          hide="true"
          // valueReason={reason}
          valueReason={"Callback Request"}
          appoimentDate={isToday == true ? todayDate : tomorrowDate}
          disbleFooter={addText ? true : false}
          // disbleFooter={slotsCard ? true : false}
          slotsTime={slotsTime}
          selectslot={selectedCard}
          appTime={isToday == true ? todayAppTime : tommorowAppTime}
          ValueForVitalText={vitalTextValue}
        // Comments={Comments}
        />
      </div>

    </div>
  );
};

export default SelectCallback;
