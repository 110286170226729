import { ActionTypes } from "../constants/action-types";
const intialState = {
  config: [],
};

export const AppConfig = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.App_Config:
      return { ...state, config: payload };
    default:
      return state;
  }
};
