import { ActionTypes } from "../constants/action-types";
const intialState = {
  showVirtualWaitingRoom: false,
  isCallEndModal: false,
  setCallID: null,
};

export const meeting = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SHOW_VIRTUAL_WAITING_ROOM:
      return { ...state, showVirtualWaitingRoom: payload };
    case ActionTypes.CALL_END:
      return { ...state, isCallEndModal: payload };
    case ActionTypes.SET_CALL_ID:
      return { ...state, setCallID: payload };
    default:
      return state;
  }
};
