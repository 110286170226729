/* eslint-disable import/no-anonymous-default-export */
const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_COGNITO_APP_CLIENT_ID,
  REACT_APP_S3_REGION,
  REACT_APP_S3_BUCKET,
  // REACT_APP_S3_ASSET_FOLDER,
  REACT_APP_ASSET_URL,
  REACT_APP_API_URL,
  REACT_APP_PROJECT_TYPE,
  REACT_APP_PAYMENT_API,
  REACT_PAYMENT_TYPE,
  REACT_APP_SQUARE_APPLICATION_ID,
  REACT_APP_SQUARE_LOCATION_ID
} = process.env

//Log Test

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  cognito: {
    REGION: REACT_APP_COGNITO_REGION,
    USER_POOL_ID: REACT_APP_COGNITO_USER_POOL_ID,
    IDENTITY_POOL_ID: REACT_APP_COGNITO_IDENTITY_POOL_ID,
    APP_CLIENT_ID: REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  s3: {
    REGION: REACT_APP_S3_REGION,
    BUCKET: REACT_APP_S3_BUCKET,
    ASSET_FOLDER: 'onrx',
  },
  assetUrl: REACT_APP_ASSET_URL,
  apiUrl: REACT_APP_API_URL,
  ONRX_API: {
    apiGateway: {
      REGION: 'ca-central-1',
      URL: 'https://api.dev.onrx.ca/',
    },
  },
  projectType: REACT_APP_PROJECT_TYPE,
  paymentUrl: REACT_APP_PAYMENT_API,
  paymentType: REACT_PAYMENT_TYPE,
  applicationId : REACT_APP_SQUARE_APPLICATION_ID,
  locationId : REACT_APP_SQUARE_LOCATION_ID,


  // JWTSECRATE: 'mediasoupisbest',
  // MediaSoupUrl: 'https://dev.stream.onrx.ca',
  // wssUrl: "wss://meetingserver.yqgtech.com/?connection=",
  // iceServer: { iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }, { urls: ["turn:dev.turn.onrx.ca:3478"], username: "uhc", credential: "Hhands@12345" }, { urls: ["stun:dev.stun.onrx.ca:3478"], username: "uhc", credential: "Hhands@12345" }] }
  MediaSoupUrl: `https://stream.dev.onrx.ca`,
  JWTSECRATE: 'mediasoupisbest',
  meetingAppId: 'yqgpros_demo',
  wssUrl: 'wss://meetingserver.dev.onrx.ca/?connection=',
  iceServer: {
    iceServers: [
      { urls: ['stun:stun.l.google.com:19302'] },
      { urls: ['turn:turn.dev.onrx.ca:3478'], username: 'uhc', credential: 'Hhands@12345' },
      { urls: ['stun:stun.dev.onrx.ca:3478'], username: 'uhc', credential: 'Hhands@12345' },
    ],
  },
}
