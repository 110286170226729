import { ActionTypes } from "../constants/action-types";
const intialState = {
  doctorList: [],
};

export const doctorListReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_DOCTOR_LIST:
      return { ...state, DOC_LIST: payload };
    default:
      return state;
  }
};

const intialStateBySelectedDoctor = {
  selectedDocter: [],
};

export const selectedDocter = (state = intialStateBySelectedDoctor, { type, payload }) => {
  switch (type) {
    case ActionTypes.SELECTED_DOCTOR:
      return { ...state, doc: payload };
    default:
      return state;
  }
};


const intialStateBySelectedDoctorProfile = {

};

export const selectedDocterProfile = (state = intialStateBySelectedDoctorProfile, { type, payload }) => {
  switch (type) {
    case ActionTypes.SELECTED_DOCTOR_PROFILE:
      return { ...state, doc_PRO: payload };
    default:
      return state;
  }
};


