import { useEffect, useState } from "react";
import {
  Steps,
  Button,
  Modal,
  Form,
  Input,
  Cascader,
  Typography,
  Radio,
  Table,
  Tag,
  Space,
  Tooltip,
  Popconfirm,
  Spin,
  notification,
  DatePicker,
  Alert,
  Select,
} from "antd";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import SSEHandler from "../../lib/SSEHandler";
import WebrtcCallHandler from "../../lib/WebrtcCallHandler";
import style from "./style.module.css";
import Footer from "components/footer";
import {
  AppointmentRequestType,
  loadWhichVersion,
  selectAppointmentType,
  selectPaymentProvider,
  setInsurance,
  setSelectedFamilyMember,
  setUser,
} from "../../redux/actions/userActions";
import * as VirtualWaitingRoomService from "../../services/virtualWaitingRoom";
import { UPDATEDGET, UPDATEDPOST, UPDATEDPUT } from "services/common.api";
import { EditOutlined } from "@material-ui/icons";
import Lottie from "react-lottie";
import moment from "moment";
import Type from "../../config";
import axios from "axios";
import MaskInput from "react-maskinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { TopBar } from "components";
import PageHeader from "components/pageHeader";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


let callPayload: any;

const AppoimentType = () => {
  const { Step } = Steps;
  const dispatch = useDispatch();
  const whichVersion = useSelector(
    (state: any) => state.curentAppointment.whichVersion
  );
  const currentUserDetails: any = useSelector(
    (state: any) => state.userReducer.user
  );
  console.log("currentUserDetails", currentUserDetails);
  const familyMemberState: any = useSelector(
    (state: any) => state?.curentAppointment?.familyMember
  );
  console.log("familyMemberState", familyMemberState);
  const currentAppointmentState: any = useSelector(
    (state: any) => state?.curentAppointment?.appointmentRequestType
  );
  console.log("currentAppointmentState", currentAppointmentState);
  const { Option } = Select;
  const [formOhipCard] = Form.useForm();
  const appointmentTypeNew = useSelector((state: any) => state);
  const [showVirtualWaitingRoom, setShowVirtualWaitingRoom] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  // const [selectedCard, setSelectedCard] = useState("fastService");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [invocation, setInvocation] = useState<any>({});
  const [invocationLoading, setInvocationLoading] = useState(false);
  const [isCallBack, setCallBack] = useState(Boolean);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedWalkInVisit, setSelectedWalkInVisit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [familyMember, setFamilyMember] = useState([]);
  const [updateFamilyMember, setUpdateFamilyMember] = useState([]);
  const [isOwn, setIsOwn] = useState(true);
  const [familyModel, setFamilyModel] = useState(false);
  const [selectAppointment, setSelectAppointment] = useState("Own");
  const [callback, setCallback] = useState([] as any);
  const [fastestServiceAvailability, setFastestServiceAvailability] =
    useState(undefined);
  const [isSlotSelected, setSlotSelected] = useState(false);
  const [insuranceData, setInsuranceData] = useState([] as any);
  const [isOhipCardModalVisible, setIsOhipCardModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({} as any);
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [btnLoader, setBtnLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const userData = useSelector((state: any) => state.userReducer.user);
  const [openModal, setOpenModal] = useState(false);
  let config = useSelector((state: any) => state.AppConfig.config);
  const userAgent = navigator.userAgent;

  console.log(config, "config");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./waiting.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const familyMembers = [
    { label: "self", value: "self" },
    { label: "person1", value: "person1" },
  ];

  const cardClick = (selectedCard) => {
    console.log(selectedCard, "selectedCard selectedCard");
    if (selectedCard === "Today" || selectedCard == "TodayPrivatePay") {
      if (
        selectedPayment != null &&
        selectedPayment != "" &&
        selectedPayment != undefined
      ) {
        setSlotSelected(true);
        setSelectedCard(selectedCard);
        dispatch(selectAppointmentType("other"));
      } else {
        notification.warning({
          message: "Please select payment type first.",
        });
      }

      // history.push("/searchDoctor");
      // if (selectedPayment != null && selectedPayment != "" && selectedPayment != undefined) {
      //   setSlotSelected(true);
      // }

      // setSelectedCard(selectedCard);
      // dispatch(selectAppointmentType("other"));
      // // history.push("/searchDoctor");
    } else if (
      selectedCard === "callback" ||
      selectedCard === "CallbackPrivatePay"
    ) {
      if (
        selectedPayment != null &&
        selectedPayment != "" &&
        selectedPayment != undefined
      ) {
        setSlotSelected(true);
        setCallBack(true);
        setSelectedCard(selectedCard);
        dispatch(selectAppointmentType("CallBackRequest"));
      } else {
        notification.warning({
          message: "Please select payment type first.",
        });
      }
      // if (selectedPayment != null && selectedPayment != "" && selectedPayment != undefined) {
      //   setSlotSelected(true);
      // }
      // setCallBack(true);
      // setSelectedCard(selectedCard);
      // dispatch(selectAppointmentType("CallBackRequest"));
    } else {
      console.log(selectedPayment, "payment paymnet6tet6e6");
      if (
        selectedPayment != undefined &&
        selectedPayment != "" &&
        selectedPayment != null
      ) {
        setSlotSelected(true);
        setSelectedCard(selectedCard);
        // dispatch(selectAppointmentType("fastService"));
        // startVirtualWaitingRoom();
      } else {
        notification.warning({
          message: "Please select payment type first.",
        });
      }
    }
  };

  const logEvents = async (data) => {
    console.log(data, "LOG EVENTTT")
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {
    //     const paymentTypeFromEnv = Type.paymentType;
    // console.log('paymentTypeFromEnv454', paymentTypeFromEnv);

    if (config.appointment_type === "OHIP") {
      setSelectedPayment("OHIP");
    } else if (config.appointment_type === "PRIVATE_PAY") {
      setSelectedPayment("PRIVATE_PAY");
    } else {
      console.log("both are selected");
    }

    dispatch(selectPaymentProvider(config.appointment_type));

    // const paymentprivatepay = process.env.PAYMENT_PRIVATE_TYPE;

    // console.log('paymentprivatepay',paymentprivatepay);
  }, []);

  const paymentClick = async (selectedPayment) => {
    setSelectedPayment(selectedPayment);
    console.log(selectedPayment, "selectedPayment selectedPayment");
    if (selectedPayment === "PRIVATE_PAY") {
      if (
        selectedCard != null &&
        selectedCard != "" &&
        selectedCard != undefined
      ) {
        setSlotSelected(true);
      }
      // setSelectedPayment("");
      setSelectedPayment(selectedPayment);
      dispatch(selectPaymentProvider(selectedPayment));
      dispatch(setInsurance("PRIVATE_PAY"));
    } else if (selectedPayment === "OHIP") {
      setLoader(true);
      if (
        selectedCard != null &&
        selectedCard != "" &&
        selectedCard != undefined
      ) {
        setSlotSelected(true);
      }
      setSelectedPayment(selectedPayment);
      const result = insuranceData.find(
        (item) => item.insurance_type === "OHIP"
      );

      if (result) {
        // Check for Valid or not
        validateOhipCard(result, selectedPayment);
      } else {
        // Add Ohip Card if not
        setLoader(false);
        setIsOhipCardModalVisible(true);
      }
    } else {
      if (
        selectedCard != null &&
        selectedCard != "" &&
        selectedCard != undefined
      ) {
        setSlotSelected(true);
      }
      setSelectedPayment("");
      // setSelectedPayment(selectedPayment);
      dispatch(selectPaymentProvider(selectedPayment));
    }
  };

  const validateOhipCard = async (event, selectedPayment) => {
    let fname = event.name.split(" ")[0];
    // let middlename = event.name.split(" ")[1];
    let lname = event.name.split(" ")[1];
    let ohipNumber = event.insurance_number?.replaceAll("-", "");
    console.log(ohipNumber, "ohipnumberohipnumber validOhipCard");
    let data = {
      // "provider-number": "020497",
      hcn: ohipNumber ? ohipNumber : null,
      // HCN: currentUserDetails?.Insurancesvcnum,
      // familyMemberState?.ohipNumber,
      vc: event.insurance_vc,
      user: fname + " " + lname,
      // "client-id": "1704898766014x798343235103228000"
      // User: fname + " " + middlename + " " + lname,
    };
    console.log(data, "Validate APi Object");

    await UPDATEDPOST("ohip/check", data)
      .then(
        async (response: any) => {

          let rawLogs = {
            "userIdentity": {
              "loggedinuser": `${userData?.Email}`,
              "role":`${userData?.role}`,
              "createdate":new Date().toISOString()
            },
            "action": "VALIDATE",
            "source": "Appointment Type Page",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": "ohip/check",
            "requestParameters": data,
            "responsestatus": response?.status
          }
      
          let logBody = {
            // message: `User - ${userData?.sub} - Check OHIP Card - Appointment Type Page`,
            message: rawLogs,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
          }
      
          logEvents(logBody);
          
          console.log(response, "responce");
          setLoader(false);
          if (response?.data?.response["MOH-card-eligible"] == false) {
            notification.warning({
              message: "Invalid OHIP Card",
            });
          } else {
            console.log("in else");
            // setSlotSelected(true);
            // setSelectedPayment("");
            setSelectedPayment(selectedPayment);
            dispatch(setInsurance(event));
            dispatch(selectPaymentProvider(selectedPayment));
          }
        },
        (err) => {
          setLoader(false);
          console.log(err);
        }
      );
  };

  const getInsuranceDetails = async () => {
    console.log(familyMemberState);
    let uid;
    if (currentAppointmentState == "Own") {
      uid = currentUserDetails.id;
    } else {
      uid = familyMemberState?.familymemberid;
    }

    

    

    try {
      setLoader(true);
      const res = await UPDATEDGET(`insurance/${uid}`);

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Appointment Type Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `insurance/${uid}`,
        "requestParameters": {},
        "responsestatus": res?.status
      }
  
      let logBody = {
        // message: `User - ${userData?.sub} - Check OHIP Card - Appointment Type Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
      }
  
      logEvents(logBody);

      console.log(res?.data, "getInsuranceInfo");
      setInsuranceData(res?.data);
      setLoader(false);
    } catch (err) {
      console.log("error", err);
      setLoader(false);
    }
  };

  // const goToBack = () => {
  //   history.goBack();
  // };
  // const handleOk = () => {
  //   setIsOpenModal(false);
  // };
  const handleCancel = () => {
    setSelectedCard("");
    WebrtcCallHandler.getInstance().cleanUp();
    //setIsOpenModal(false);
    if (invocation) {
      let requestObject = {
        invocationId: invocation.appointmentId,
        eventId: invocation.appointmentId,
        callId: invocation.callId,
      };
      setInvocationLoading(true);
      VirtualWaitingRoomService.cancelVirtualWaitingRoom(requestObject)
        .then((result) => {
          setInvocationLoading(false);
          // dispatch({
          //   type: "SHOW_VIRTUAL_WAITING_ROOM",
          //   payload: false,
          // });
          setShowVirtualWaitingRoom(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          setInvocationLoading(false);
        });
    }
  };
  const selectWalkInVisit = (walkInVisit) => {
    setSelectedWalkInVisit(walkInVisit);
  };

  const getDateFormated = (date) => {
    if (date) {
      return date.replace("/", "-").replace("/", "-");
    } else {
      return date;
    }
  };

  const getCallbackAvailability = async () => {

    try {
      const res = await UPDATEDGET(`callbackrequests/available`);

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Appointment Type Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": "callbackrequests/available",
        "requestParameters": {},
        "responsestatus": res?.status
      }
  
      let logBody = {
        // message: `User - ${userData?.sub} - Get Callback Availability - Appointment Type Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
      }
  
      logEvents(logBody);

      console.log(res?.data, "getCallbackAvailability");
      setCallback(res?.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getFasterServiceAvailability = async () => {

    try {
      const res = await UPDATEDGET(`companyemployee/availability`);

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Appointment Type Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": "companyemployee/availability",
        "requestParameters": {},
        "responsestatus": res?.status
      }
  
      let logBody = {
        // message: `User - ${userData?.sub} - Get Faster Service Availability - Appointment Type Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
      }
  
      logEvents(logBody);

      console.log(res?.data?.availability, "getFasterServiceAvailability");
      setFastestServiceAvailability(res?.data?.availability);
    } catch (err) {
      console.log("error", err);
    }
  };

  const startVirtualWaitingRoom = () => {
    console.log("Loggeded !!!!!!");
    const roomId = uuid();
    console.log("roomId: ", roomId);

    let currentUserDetailsNew = currentUserDetails;
    currentUserDetailsNew.CompanyEmployee = [];

    console.log(currentUserDetailsNew, "currentUserDetailsNew");
    const walkinInRequest = {
      patientId: currentUserDetails?.sub,
      bookedBy: currentUserDetails.id,
      detail: "Virtual waiting room",
      patientDetails: {
        ...currentUserDetailsNew,
      },
      appointmentId: roomId,
      paymenttype: selectedPayment,
      callType: "video",
      roomId: roomId,
      callId: uuid(),
      createdAt: new Date().getTime(),
    };
    if (currentAppointmentState == "familyMember") {
      walkinInRequest.patientDetails = {
        ...currentUserDetailsNew,
        FirstName: familyMemberState?.userName,
        LastName: "",
        id: familyMemberState?.familymemberid,
        name: familyMemberState?.userName,
      };
    }

    WebrtcCallHandler.getInstance().addExtraListenerWithForcefullyAdded(
      "onUserJoined",
      function (participant) {
        callPayload = {
          type: "video",
          isSFURequired: false,
          callInfo: {
            isSFURequired: false,
            toUsers: [
              {
                userId: participant?.userData?.sub,
                name: participant?.userData?.name,
                avatar: "",
                userType: "doctor",
              },
            ],
          },
          fromUser: {
            userId: currentUserDetails?.sub,
            name:
              currentAppointmentState == "familyMember"
                ? familyMemberState?.userName
                : currentUserDetails?.name,
            avatar: "",
            userType: "paitent",
          },
        };

        SSEHandler.getInstance().eventEmitter.emit("ShowCallUI", callPayload);
        // dispatch({
        //   type: "SHOW_VIRTUAL_WAITING_ROOM",
        //   payload: false,
        // });
        setShowVirtualWaitingRoom(false);
      },
      false
    );

    WebrtcCallHandler.getInstance().addExtraListenerWithForcefullyAdded(
      "onConnected",
      function () {
        WebrtcCallHandler.getInstance().getMeetingHandler().startMeeting();

        VirtualWaitingRoomService.startVirtualWaitingRoom(walkinInRequest)
          .then((result) => {
            console.log("result: ", result);
            if (result) {
              let appointment = result;
              setInvocationLoading(false);
              setInvocation(appointment);
            }
          })
          .catch((error) => {
            setInvocationLoading(false);
            // dispatch({
            //   type: "SHOW_VIRTUAL_WAITING_ROOM",
            //   payload: false,
            // });
            setShowVirtualWaitingRoom(false);
          });
      },
      false
    );

    SSEHandler.getInstance().onNewCallRequest({
      roomId: roomId,
      isSFURequired: false,
      type: "video",
      paymenttype: selectedPayment,
    });

    // dispatch({
    //   type: "SHOW_VIRTUAL_WAITING_ROOM",
    //   payload: true,
    // });
    setShowVirtualWaitingRoom(true);

    setInvocationLoading(true);
  };

  useEffect(() => {

    setOpenModal(true);
    console.log(currentAppointmentState);

    if (Type.projectType != "without_problem") {
      dispatch(loadWhichVersion("with_problem"));
    } else {
      dispatch(loadWhichVersion("without_problem"));
    }

    // dispatch(setSelectedFamilyMember([]))
    window.scrollTo(0, 0);
    dispatch(AppointmentRequestType("Own"));
    getCallbackAvailability();
    getFasterServiceAvailability();
    if (appointmentTypeNew?.curentAppointment?.appointmentType == "other") {
      setSelectedCard("Today");
    } else if (
      appointmentTypeNew?.curentAppointment?.appointmentType ==
      "CallBackRequest"
    ) {
      setSelectedCard("callback");
    } else {
      // setSelectedCard("");
      setSelectedCard(selectedCard);
      // setSelectedCard("fastService");
    }
  }, []);

  useEffect(() => {

    if (currentAppointmentState == "Own") {
      getInsuranceDetails();
    }
  }, [currentAppointmentState]);

  useEffect(() => {
    if (currentAppointmentState == "familyMember") {
      getInsuranceDetails();
    }
  }, [familyMemberState]);

  const handleCancelVirtualRoom = () => {
    setSelectedCard("");
    setShowVirtualWaitingRoom(false);
  };

  const checkFamilyMembar = (data) => {
    setSelectAppointment(data);
    console.log(data, "family member select");
    if (data == "familyMember") {
      setIsOwn(false);
      dispatch(AppointmentRequestType("familyMember"));
      dispatch(selectPaymentProvider({}));
      setSelectedPayment("");
      setSelectedCard("");
      setIsModalVisible(true);
      getFamilyMember();
    } else {
      setIsOwn(true);
      setSelectedPayment("");
      setSelectedCard("");
      dispatch(AppointmentRequestType("Own"));
      dispatch(selectPaymentProvider({}));
    }
  };

  // const checkFamilyMembar = (event) => {
  //   setSelectAppointment(event.target.value);
  //   console.log(event.target.value, "family member select");
  //   if (event.target.value == "familyMember") {
  //     setIsOwn(false);
  //     dispatch(AppointmentRequestType("familyMember"));
  //     dispatch(selectPaymentProvider({}));

  //     setSelectedPayment("");
  //     setSelectedCard("");

  //     setIsModalVisible(true);
  //     getFamilyMember();
  //   } else {
  //     setIsOwn(true);
  //     setSelectedPayment("");
  //     setSelectedCard("");
  //     dispatch(AppointmentRequestType("Own"));
  //     dispatch(selectPaymentProvider({}));
  //   }
  // };

  const handleOkFamily = () => {
    setIsModalVisible(false);
  };

  const handleCancelFamily = () => {
    setIsModalVisible(false);
    setIsOwn(true);
    setSelectAppointment("Own");
    dispatch(AppointmentRequestType("Own"));
  };

  const getFamilyMember = async () => {

    try {
      setLoader(true);
      // const {
      //   data: {
      //     body: { FamilyMembers },
      //   },
      // } = await UPDATEDGET(`userfamilymembers?id=${currentUserDetails.id}`);

      const response = await UPDATEDGET(`userfamilymembers?id=${currentUserDetails.id}`);
      const { data } = response;
      const { body } = data;
      const { FamilyMembers } = body;


      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Appointment Type Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `userfamilymembers?id=${currentUserDetails.id}`,
        "requestParameters": {},
        "responsestatus": response?.status
      }

    let logBody = {
      // message: `User - ${userData?.sub} - Fetch Family Members - Appointment Type Page`,
      message: rawLogs,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
    }

    logEvents(logBody);

      if (FamilyMembers) {
        setLoader(true);
        setFamilyMember(FamilyMembers);
        setLoader(false);
      } else {
        setLoader(true);
        setFamilyMember([]);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("err: ", err);
    }
  };

  const data = [
    {
      title: "Family Member",
      dataIndex: "userName",
      key: "userName",
      render: (userName) => <>{userName}</>,
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
      render: (relationship) => (
        <Tag color="var(--light-primary-bg-color)" style={{ borderRadius: 5 }}>
          <p style={{ margin: 0 }}>{relationship}</p>
        </Tag>
      ),
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (dateOfBirth) => <>{moment(dateOfBirth).format("DD-MMM-YYYY")}</>,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    // {
    //   title: 'Address',
    //   dataIndex: 'address',
    //   key: 'address',
    //   render: (text) => <>
    //     <p style={{ width: 140, whiteSpace: "pre-wrap" }}>{text}</p>
    //   </>
    // },
    {
      title: "Action",
      key: "action",
      dataIndex: "",
      render: (data) => (
        <Space size="middle">
          <Tooltip
            placement="bottom"
            title="Select Member"
            key={"var(-primary-color)"}
          >
            <Button
              onClick={() => {
                setSelectedPayment("");
                dispatch(setSelectedFamilyMember(data));
                setIsModalVisible(false);
              }}
              shape="round"
              type="primary"
              size="small"
            >
              Select
            </Button>
            {/* <button className="button-Edit-Icon" role="button"
              onClick={() => {
                dispatch(setSelectedFamilyMember(data))
                setIsModalVisible(false)
              }}
            >
              select
              <FontAwesomeIcon icon={faPlus} />
            </button> */}
          </Tooltip>
        </Space>
      ),
    },
  ];

  const editFamilyMember = () => {
    setIsModalVisible(true);
    getFamilyMember();
  };

  const familyModelClose = async () => {
    setFamilyModel(false);
    try {
      setLoader(true);
      const {
        data: {
          body: { FamilyMembers },
        },
      } = await UPDATEDGET(`userfamilymembers?id=${currentUserDetails.id}`);
      if (FamilyMembers) {
        setLoader(true);
        console.log(FamilyMembers, "familyMembers");
        console.log(familyMemberState, "familyMemberState.familymemberid");
        var result = FamilyMembers.filter((obj) => {
          return obj.id === familyMemberState.id;
        });
        console.log(result[0], "result----");
        dispatch(setSelectedFamilyMember(result[0]));
        setLoader(false);
      } else {
        setLoader(true);
        setFamilyMember([]);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("err: ", err);
    }
  };

  const familyModelOpen = () => {
    setFamilyModel(true);
    setSelectedFamilyMember({});
    // setClick(false);
  };

  const handleOhipSubmit = async (event: any) => {

    console.log("Success:", event);
    console.log("Success:", dateOfBirth);
    console.log(event.ohipNumber);
    let ohipNumber = event.ohipNumber.replaceAll("-", "");
    console.log(ohipNumber, "ohipnumberohipnumber handleOhipSubmit");
    let data = {      
      hcn: ohipNumber,
      vc: event.insuranceVersionCode,
      user: event.fname + " " + event.lname,
    };
    console.log(data, "check Api  Data");
    setBtnLoader(true);
    await UPDATEDPOST("ohip/check", data)
      .then(
        async (response: any) => {
          let uid;
          let fname;
          let lname;

          let rawLogs = {
            "userIdentity": {
              "loggedinuser": `${userData?.Email}`,
              "role":`${userData?.role}`,
              "createdate":new Date().toISOString()
            },
            "action": "VALIDATE",
            "source": "Appointment Type Page",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": "ohip/check",
            "requestParameters": data,
            "responsestatus": response?.status
          }
      
          let logBody = {
            // message: `User - ${userData?.sub} - Check OHIP Card - Appointment Type Page`,
            message: rawLogs,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
          }
      
          logEvents(logBody);

          if (currentAppointmentState == "Own") {
            uid = currentUserDetails.id;
            fname = currentUserDetails?.FirstName;
            lname = currentUserDetails?.LastName;
          } else {
            uid = familyMemberState?.familymemberid;
            fname = (familyMemberState?.userName).split(" ")[0];
            lname = (familyMemberState?.userName).split(" ")[1];
          }
          console.log(response, "responce");
          let D = moment(dateOfBirth, "YYYY/MM/DD").format("YYYY-MM-DD");
          if (response?.data?.response["MOH-card-eligible"] == false) {
            setLoader(false);

            setErrorMsg("Invalid OHIP Card");
            setShowError(true);
            setBtnLoader(false);
          } else {
            if (
              response?.data?.response["MOH-Message"] == "Invalid version code"
            ) {
              setLoader(false);
              setErrorMsg("Invalid version code");
              setShowError(true);
              setBtnLoader(false);
            } else {
              if (response?.data?.response["MOH-card-status"] == "invalid") {
                setLoader(false);
                setErrorMsg("Invalid or Expired health card");
                setShowError(true);
                setBtnLoader(false);
              } else {
                if (response?.data?.response.DOB !== D) {
                  setLoader(false);
                  setErrorMsg("Date of birth should match with OHIP");
                  setShowError(true);
                  setBtnLoader(false);
                } else {
                  if (
                    response?.data?.response["First-name"].toLowerCase() !==
                    event.fname.toLowerCase()
                  ) {
                    setLoader(false);
                    setErrorMsg("First name should match with OHIP");
                    setShowError(true);
                    setBtnLoader(false);
                  } else {
                    if (
                      response?.data?.response["First-name"].toLowerCase() !==
                      fname?.toLowerCase()
                    ) {
                      setLoader(false);
                      if (currentAppointmentState == "Own") {
                        setErrorMsg(
                          "Your account profile details should match with the OHIP card details"
                        );
                      } else {
                        setErrorMsg(
                          "Family member account profile details should match with the OHIP card details"
                        );
                      }
                      // setErrorMsg("Change the profile details according to the OHIP card")
                      // setErrorMsg("First name should match with profile info")
                      setShowError(true);
                      setBtnLoader(false);
                    } else {
                      if (
                        response?.data?.response["Last-name"].toLowerCase() !==
                        event.lname.toLowerCase()
                      ) {
                        setLoader(false);
                        setErrorMsg("Last name should match with OHIP");
                        setShowError(true);
                        setBtnLoader(false);
                      } else {
                        if (
                          response?.data?.response[
                            "Last-name"
                          ].toLowerCase() !== lname?.toLowerCase()
                        ) {
                          setLoader(false);
                          // setErrorMsg("Last name should match with profile info")
                          // setErrorMsg("Change the profile details according to the OHIP card")
                          if (currentAppointmentState == "Own") {
                            setErrorMsg(
                              "Your account profile details should match with the OHIP card details"
                            );
                          } else {
                            setErrorMsg(
                              "Family member account profile details should match with the OHIP card details"
                            );
                          }
                          setShowError(true);
                          setBtnLoader(false);
                        } else {
                          try {
                            setLoader(true);


                            UPDATEDPUT(
                              `user/patient/profile/${userData.cognitoid}`,
                              {
                                FirstName: userData?.FirstName,
                                LastName: userData?.LastName,
                                Email: userData?.Email,
                                DOB: dateOfBirth,
                                gender: event?.gender,
                              }
                            ).then((res) => {

                              let rawLogs = {
                                "userIdentity": {
                                  "loggedinuser": `${userData?.Email}`,
                                  "role":`${userData?.role}`,
                                  "createdate":new Date().toISOString()
                                },
                                "action": "UPDATE",
                                "source": "Appointment Type Page",
                                "sourceIPAddress": "3.99.184.26",
                                "userAgent": userAgent,
                                "ipAddress": localStorage.getItem('ipAddress'),
                                "apiendpoint": `user/patient/profile/${userData.cognitoid}`,
                                "requestParameters": {
                                  FirstName: userData?.FirstName,
                                  LastName: userData?.LastName,
                                  Email: userData?.Email,
                                  DOB: dateOfBirth,
                                  gender: event?.gender,
                                },
                                "responsestatus": res?.status
                              }
  
                              let logBody = {
                                // message: `User - ${userData?.sub} - Update Patient Profile - Appointment Type Page`,
                                message: rawLogs,
                                logGroupName: "ONRx-Patient-Portal",
                                logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
                              }
                          
                              logEvents(logBody);

                              let patientAdd = {
                                "userIdentity": {
                                  "loggedinuser": `${userData?.Email}`,
                                  "role":`${userData?.role}`,
                                  "createdate":new Date().toISOString()
                                },
                                "action": "UPDATE",
                                "source": "Appointment Type Page",
                                "sourceIPAddress": "3.99.184.26",
                                "userAgent": userAgent,
                                "ipAddress": localStorage.getItem('ipAddress'),
                                "apiendpoint": `user/patient/profile/${userData.cognitoid}`,
                                "requestParameters": {
                                  FirstName: userData?.FirstName,
                                  LastName: userData?.LastName,
                                  Email: userData?.Email,
                                  DOB: dateOfBirth,
                                  gender: event?.gender,
                                },
                                "responsestatus": res?.status
                              }
  
                              let pateintbody = {
                                // message: `User - ${userData?.sub} - Update Patient Profile - Appointment Type Page`,
                                message: patientAdd,
                                logGroupName: "ONRx-Patient-Portal",
                                logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
                              }
                          
                              logEvents(pateintbody);

                              

                              console.log(res, "-=-=-=-=-=-=-=-=-=-=-=-=-");
                              setLoader(false);
                              // history.push("/view-profile");
                              try {

                                UPDATEDGET(
                                  `user?cognitoid=${userData.cognitoid}`
                                ).then(async (userInfo) => {

                                  let rawLogs2 = {
                                    "userIdentity": {
                                      "loggedinuser": `${userData?.Email}`,
                                      "role":`${userData?.role}`,
                                      "createdate":new Date().toISOString()
                                    },
                                    "action": "ACCESS",
                                    "source": "Appointment Type Page",
                                    "sourceIPAddress": "3.99.184.26",
                                    "userAgent": userAgent,
                                    "ipAddress": localStorage.getItem('ipAddress'),
                                    "apiendpoint": `user?cognitoid=${userData.cognitoid}`,
                                    "requestParameters": {},
                                    "responsestatus": userInfo?.status
                                  }
  
                                  let logBody2 = {
                                    // message: `User - ${userData?.sub} - Fetch User Profile - Appointment Type Page`,
                                    message: rawLogs2,
                                    logGroupName: "ONRx-Patient-Portal",
                                    logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
                                  }
                              
                                  logEvents(logBody2);

                                  let ud;
                                  ud = userInfo.data?.body;
                                  ud.userId = ud?.cognitoid;
                                  ud.sub = ud?.cognitoid;

                                  dispatch(setUser(ud));
                                  console.log(userInfo, "update user");

                                  const Ohip = {
                                    name: event?.fname + " " + event?.lname,
                                    number: ohipNumber,
                                    provider: "NA",
                                    type: "OHIP",
                                    vc: event?.insuranceVersionCode,
                                    user_id: uid,
                                    gender: event?.gender,
                                    DOB: dateOfBirth,
                                    is_valid: 1,
                                  };
                                  console.log(Ohip, "Ohip");

                                  

                                  try {
                                    const res = await UPDATEDPOST("insurance", Ohip);

                                    let rawLogs = {
                                      "userIdentity": {
                                        "loggedinuser": `${userData?.Email}`,
                                        "role":`${userData?.role}`,
                                        "createdate":new Date().toISOString()
                                      },
                                      "action": "INSERT",
                                      "source": "Appointment Type Page",
                                      "sourceIPAddress": "3.99.184.26",
                                      "userAgent": userAgent,
                                      "ipAddress": localStorage.getItem('ipAddress'),
                                      "apiendpoint": "insurance",
                                      "requestParameters": Ohip,
                                      "responsestatus": res?.status
                                    }
  
                                    let logBody = {
                                      // message: `User - ${userData?.sub} - Add Insurance Detail - Appointment Type Page`,
                                      message: rawLogs,
                                      logGroupName: "ONRx-Patient-Portal",
                                      logStreamName: `User - ${userData?.sub} - Appointment Type Page`,
                                    }
                                
                                    logEvents(logBody);

                                    console.log(
                                      res,
                                      "family insurances------0 "
                                    );
                                    notification.success({
                                      message: "OHIP details added succesfully",
                                    });
                                    // setSelectedPayment("");
                                    setSelectedPayment("OHIP");
                                    setSlotSelected(true);
                                    getInsuranceDetails();
                                    setBtnLoader(false);
                                    setIsOhipCardModalVisible(false);
                                    dispatch(selectPaymentProvider("OHIP"));
                                    dispatch(setInsurance(event));
                                    formOhipCard.resetFields();
                                  } catch (err) {
                                    setBtnLoader(false);
                                    console.log("error", err);
                                  }
                                });
                              } catch (error) {
                                setBtnLoader(false);
                                setLoader(false);
                                console.log(error);
                              }
                            });
                          } catch (error) {
                            setBtnLoader(false);
                            setLoader(false);
                            console.log(error);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        (err) => {
          setBtnLoader(false);
          console.log(err);
        }
      );
  };

  const handleCancelOhip = () => {
    dispatch(setInsurance({}));
    setSelectedPayment("");
    setIsOhipCardModalVisible(false);
  };

  const handleChange = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    if (errorMsg == "Invalid OHIP Card" && event.target.name == "ohipNumber") {
      setShowError(false);
    } else if (
      errorMsg == "Invalid version code" &&
      event.target.name == "insuranceVersionCode"
    ) {
      setShowError(false);
    } else if (
      (errorMsg == "First name should match with OHIP" ||
        errorMsg ==
          "Your account profile details should match with the OHIP card details") &&
      event.target.name == "fname"
    ) {
      setShowError(false);
    }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == 'Change the profile details according to the OHIP card') && event.target.name == 'fname') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == 'First name should match with profile info') && event.target.name == 'fname') {
    //   setShowError(false);
    // }
    else if (
      (errorMsg == "Last name should match with OHIP" ||
        errorMsg ==
          "Your account profile details should match with the OHIP card details") &&
      event.target.name == "lname"
    ) {
      setShowError(false);
    }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == 'Change the profile details according to the OHIP card') && event.target.name == 'lname') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == 'Last name should match with profile info') && event.target.name == 'lname') {
    //   setShowError(false);
    // }
    else if (errorMsg == "Invalid or Expired health card") {
      setShowError(false);
    }

    const name = event?.target?.name;
    const value = event?.target?.value;
    console.log(name, value);
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    console.log(formValues, "formValues of Ohip Card");
  };

  const onChangeDateOfBirth = (date, dateString) => {
    console.log("dataeateaeataeata", dateString);
    setDateOfBirth(dateString);
    if (errorMsg == "Date of birth should match with OHIP") {
      setShowError(false);
    }
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <TopBar isBookAppointment={true} />

      <Modal
        footer={null}
        title="Note"
        visible={openModal}
        onCancel={handleCancelModal}
        width="1100px"
      >
        <div>
          The ONRx platform and it's services are not meant for medical
          emergency!
        </div>
        <br />
        <div>
          If you have a medical emergency call{" "}
          <a style={{ color: "var(--primary-color)" }}>911 immediately</a> or
          visit the emergency room of the nearest hospital.
        </div>
        <br />
        <div>
          Please note that our Virtual Care Platform is designed for
          non-emergency medical consultation and advice only. It does not
          provide diagnosis or treatment for medical emergencies. If you are
          experiencing a medical emergency, please seek immediate medical
          attention by visiting the nearest emergency department. We are not
          liable for any damages or losses arising from the use of our platform
          or reliance on the information provided. By using our Virtual Care
          Platform, you acknowledge that you have read and understood this
          disclaimer and agree to its terms.
        </div>
        <br />

        <div className="d-flex justify-content-end">
          <Button
            className={style.btnGreen}
            // loading={btnLoader}
            onClick={() => handleCancelModal()}
            // htmlType="submit"
          >
            I Agree
          </Button>
        </div>
      </Modal>

      <Modal
        visible={showVirtualWaitingRoom}
        title="Virtual Waiting Room"
        closable={true}
        className={`${style.newClass} newAllModalCss`}
        width={"100vw"}
        footer={null}
        maskClosable={false}
        onCancel={handleCancelVirtualRoom}
      >
        <div className="row">
          <div className={`${style.col_flex} col-md-12`}>
            <div className={style.div_flex_waiting}>
              <div>
                <label style={{ marginTop: 35 }}>
                  Please wait untill docotor will takeup the call...
                </label>
              </div>

              <div style={{ width: 100 }}>
                <Lottie
                  options={defaultOptions}
                  height={100}
                  width={100}
                  speed={1}
                  loop
                  controls
                  autoplay
                  isClickToPauseDisabled={true}
                />
              </div>
            </div>

            <div className="text-center" style={{ width: "65%" }}>
              <img
                style={{ width: "100%" }}
                alt="Virtual Waiting Room"
                src="../Images/virtualWaitingRoom.png"
              />
            </div>
            <div className="text-center mt-2">
              <Button
                type="primary"
                size="large"
                danger={true}
                onClick={handleCancel}
                loading={invocationLoading}
                disabled={invocationLoading}
                className={style.btnCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="content contentSelect" style={{ height: "100%" }}>
        <div className="container-fluid pb-4">
          <PageHeader
            items={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Book Appointment", link: "/appoiment-type" },
              { label: "Select Type", link: "" },
            ]}
            pageTitle="Book Appointment"
          />
          These services are only available to patients with the Ontario Health Insurance Plan (OHIP). or patients  residing in the province of Ontario.

          <div className="row mt-4 ">
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-2"></div>
            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8">
              <div className="stepDiv">
                <Steps size="small" className="custome-step" style={{fontSize:16}}>
                  <Step
                    status="finish"
                    title="Select Type"
                    className={style.stepsDiv}
                  />
                  {/* <Step status="wait" title="Select Doctor" /> */}
                  {/* <Step status="wait" title="Payment" /> */}
                  {whichVersion !== "without_problem" ? (
                    <Step status="wait" title="Problems" />
                  ) : null}
                  <Step
                    status="wait"
                    title="Appointment Window"
                    className={style.stepsDiv}
                  />
                  <Step
                    status="wait"
                    title="Book Appointment"
                    className={style.stepsDiv}
                  />
                </Steps>
              </div>
            </div>
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="row text-left">
                <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
                <div
                  className="col-sm-10 col-md-10 col-lg-10 col-xl-10"
                  style={{ paddingBottom: "4rem" }}
                >
                  <div className="pt-5">
                    <h3 className={style.lblPayment}>
                      Creating Appointment for ?
                    </h3>
                  </div>
                  <div className="row pt-2">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className={style.chooseApp}>
                        <div
                          // className="card_static_appiment"
                          onClick={() => checkFamilyMembar("Own")}
                          className={`${
                            selectAppointment === "Own"
                              ? "card_selected_appoiment"
                              : "card_static_appiment"
                          }`}
                          // style={{
                          //   width: "100%",
                          //   justifyContent: "space-around",
                          // }}
                        >
                          <div className={style.div_img}>
                            <img
                              src="Images/own1.png"
                              alt=""
                              className={style.productImg1_appoiment}
                            />
                          </div>
                          <div className={style.div_label_center}>
                            <h3 className={style.h33}>Own</h3>
                          </div>
                          {/* <div className={style.div_label_center_radio}>
                            <Radio.Group
                              onChange={checkFamilyMembar}
                              name="FamilyMembar"
                              value={selectAppointment}
                              size="middle"
                            >
                              <Radio value="Own">
                                <h4 className={style.h33}>Own</h4>
                              </Radio>
                              <Radio value="familyMember">
                                <h4 className={style.h33}>Family Member</h4>{" "}
                              </Radio>
                            </Radio.Group>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className={style.chooseApp}>
                        <div
                          // className="card_static_appiment"
                          onClick={() => checkFamilyMembar("familyMember")}
                          className={`${
                            selectAppointment === "familyMember"
                              ? "card_selected_appoiment"
                              : "card_static_appiment"
                          }`}
                          // style={{
                          //   width: "100%",
                          //   justifyContent: "space-around",
                          // }}
                        >
                          <div className={style.div_img}>
                            <img
                              src="Images/family1.png"
                              alt=""
                              className={style.productImg1_appoiment}
                            />
                          </div>
                          <div className={style.div_label_center}>
                            <h3 className={style.h33}>Family Member</h3>
                          </div>
                          {/* <div className={style.div_label_center_radio}>
                            <Radio.Group
                              onChange={checkFamilyMembar}
                              name="FamilyMembar"
                              value={selectAppointment}
                              size="middle"
                            >
                              <Radio value="Own">
                                <h4 className={style.h33}>Own</h4>
                              </Radio>
                              <Radio value="familyMember">
                                <h4 className={style.h33}>Family Member</h4>{" "}
                              </Radio>
                            </Radio.Group>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    {!isOwn && familyMemberState?.userName != "" ? (
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className={style.chooseApp}>
                          {familyMemberState ? (
                            <div
                              onClick={() => cardClick("Today")}
                              className="card_static_appiment"
                            >
                              <div
                                className={style.div_edit}
                                onClick={() => editFamilyMember()}
                              >
                                <EditOutlined className={style.iconEdit} />
                              </div>

                              <div className={style.div_img}>
                                <img
                                  src="Images/own1.png"
                                  alt=""
                                  className={style.productImg1_appoiment}
                                />
                              </div>
                              <div className={style.div_label_center}>
                                <h3
                                  className={style.h33}
                                  style={{ fontSize: 20 }}
                                >
                                  {familyMemberState?.userName}
                                </h3>
                                <p className={style.pp}>
                                  {familyMemberState?.relationship}{" "}
                                </p>
                              </div>
                            </div>
                          ) : null}

                          {
                            // familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == null ?
                            //   <>
                            //     {
                            //       familyMemberState ?
                            //         <div>
                            //           <div className="alert alert-danger" role="alert">
                            //             Card not available !
                            //           </div>
                            //           <button type="button" className="btn btn-primary" onClick={() => familyModelOpen()}>Add</button>
                            //         </div> : null
                            //     }
                            //     <AddFamilyMember
                            //       title="Family Member"
                            //       open={familyModel}
                            //       close={familyModelClose}
                            //       getFamilyMember={getFamilyMember}
                            //       editFamilyMember={familyMemberState}
                            //     />
                            //   </>
                            //   : null
                          }
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* Payment Flow */}
                  {config.appointment_type === "BOTH" ? (
                    <>
                      <div className="pt-3">
                        <h3 className={style.lblPayment}>Payment Type ?</h3>
                      </div>
                      <div className="row pt-2">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          {loader ? (
                            <div className="spinner">
                              <Spin tip="Validating your card..."></Spin>
                            </div>
                          ) : (
                            <div className={style.chooseApp}>
                              <div
                                onClick={() => paymentClick("OHIP")}
                                className={`${
                                  selectedPayment === "OHIP"
                                    ? "card_selected_appoiment"
                                    : "card_static_appiment"
                                }`}
                              >
                                <div className={style.div_img}>
                                  <img
                                    src="Images/problems/health-card1.png"
                                    alt=""
                                    className={style.productImg_appoiment}
                                  />
                                </div>
                                <div className={style.div_label_center}>
                                  <h3 className={style.h33}>Health Card (OHIP)</h3>
                                </div>

                                {selectedPayment === "OHIP" ? (
                                  <>
                                    <FontAwesomeIcon
                                      className={`${style.iconCheck}`}
                                      icon={faCheckCircle}
                                    />
                                  </>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <div className={style.chooseApp}>
                            <div
                              onClick={() => paymentClick("PRIVATE_PAY")}
                              className={`${
                                selectedPayment === "PRIVATE_PAY"
                                  ? "card_selected_appoiment"
                                  : "card_static_appiment"
                              }`}
                            >
                              <div className={style.div_img}>
                                <img
                                  src="Images/problems/credit-card1.png"
                                  alt=""
                                  className={style.productImg1_appoiment}
                                />
                              </div>
                              <div className={style.div_label_center}>
                                <h3 className={style.h33}>Do not have Health card (OHIP)</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="pt-3">
                    <h3 className={style.lblPayment}>Appointment Type ?</h3>
                  </div>

                  {selectedPayment === "PRIVATE_PAY" ? (
                    // For Private pay
                    <div className="row pt-2">
                      {/* Schhedule */}
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className={style.chooseApp}>
                          <div
                            onClick={() => cardClick("TodayPrivatePay")}
                            className={`${
                              selectedCard === "TodayPrivatePay"
                                ? "card_selected_appoiment"
                                : "card_static_appiment"
                            }`}
                          >
                            <div className={style.div_img}>
                              <img
                                src="Images/schedule1.png"
                                alt=""
                                className={style.productImg1_appoiment}
                              />
                            </div>
                            <div className={style.div_label_center}>
                              <h3 className={style.h33}>Schedule</h3>
                              <p className={style.pp}>Regular Appointment</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className={style.chooseApp}>
                          <div
                            onClick={() => cardClick("CallbackPrivatePay")}
                            className={`${
                              selectedCard === "CallbackPrivatePay"
                                ? "card_selected_appoiment"
                                : "card_static_appiment"
                            }`}
                          >
                            <div className={style.div_img}>
                              <img
                                src="Images/call-back1.png"
                                alt=""
                                className={style.productImg1_appoiment}
                              />
                            </div>
                            <div className={style.div_label_center}>
                              <h3 className={style.h33}>Callback</h3>
                              <p className={style.pp}>
                                Create Callback Request
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {fastestServiceAvailability > 0 ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <div className={style.chooseApp}>
                            <div
                              onClick={() => cardClick("fastService")}
                              className={`${
                                selectedCard === "fastService"
                                  ? "card_selected_appoiment"
                                  : "card_static_appiment"
                              }`}
                            >
                              <div className={style.div_img}>
                                <img
                                  src="Images/FastestService1.png"
                                  alt=""
                                  className={style.productImg_appoiment}
                                />
                              </div>
                              <div className={style.div_label_center}>
                                <h3 className={style.h33}>
                                  Fastest Service
                                  {fastestServiceAvailability
                                    ? " (" + fastestServiceAvailability + ")"
                                    : null}
                                </h3>
                                <p className={style.pp}>
                                  Join in Virtual Waiting Room
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        // <>
                        // </>
                      ) : (
                        <>
                          {fastestServiceAvailability <= 0 ? (
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className={style.chooseApp}>
                                <div
                                  className={style.card_static_appiment_gray}
                                >
                                  <div className={style.div_img}>
                                    <img
                                      src="Images/FastestService1.png"
                                      alt=""
                                      className={style.productImg_appoiment}
                                    />
                                  </div>
                                  <div className={style.div_label_center}>
                                    <h3 className={style.h33}>
                                      Fastest Service
                                    </h3>
                                    <p className={style.pp}>
                                      Join in Virtual Waiting Room
                                    </p>
                                    <p className={style.pp}>
                                      No doctor available
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            // <>
                            // </>
                          ) : null}
                        </>
                      )}
                    </div>
                  ) : (
                    //  For HHealth Card
                    <div className="row pt-2">
                      {!loader ? (
                        <>
                          {fastestServiceAvailability > 0 ? (
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className={style.chooseApp}>
                                <div
                                  onClick={() => cardClick("fastService")}
                                  className={`${
                                    selectedCard === "fastService"
                                      ? "card_selected_appoiment"
                                      : "card_static_appiment"
                                  }`}
                                >
                                  <div className={style.div_img}>
                                    <img
                                      src="Images/FastestService1.png"
                                      alt=""
                                      className={style.productImg_appoiment}
                                    />
                                  </div>
                                  <div className={style.div_label_center}>
                                    <h3 className={style.h33}>
                                      Fastest Service
                                      {fastestServiceAvailability
                                        ? " (" +
                                          fastestServiceAvailability +
                                          ")"
                                        : null}
                                    </h3>
                                    <p className={style.pp}>
                                      Join in Virtual Waiting Room
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            // <>
                            // </>
                          ) : (
                            <>
                              {fastestServiceAvailability <= 0 ? (
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                  <div className={style.chooseApp}>
                                    <div
                                      className={
                                        style.card_static_appiment_gray
                                      }
                                    >
                                      <div className={style.div_img}>
                                        <img
                                          src="Images/FastestService1.png"
                                          alt=""
                                          className={style.productImg_appoiment}
                                        />
                                      </div>
                                      <div className={style.div_label_center}>
                                        <h3 className={style.h33}>
                                          Fastest Service
                                        </h3>
                                        <p className={style.pp}>
                                          Join in Virtual Waiting Room
                                        </p>
                                        <p className={style.pp}>
                                          No doctor available
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                // <>
                                // </>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <div className={style.chooseApp}>
                            <div className={style.card_static_appiment_gray}>
                              <div className={style.div_img}>
                                <img
                                  src="Images/FastestService1.png"
                                  alt=""
                                  className={style.productImg_appoiment}
                                />
                              </div>
                              <div className={style.div_label_center}>
                                <h3 className={style.h33}>Fastest Service</h3>
                                <p className={style.pp}>
                                  Join in Virtual Waiting Room
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        // <></>
                      )}

                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        {!loader ? (
                          <div className={style.chooseApp}>
                            <div
                              onClick={() => cardClick("Today")}
                              className={`${
                                selectedCard === "Today"
                                  ? "card_selected_appoiment"
                                  : "card_static_appiment"
                              }`}
                            >
                              <div className={style.div_img}>
                                <img
                                  src="Images/schedule1.png"
                                  alt=""
                                  className={style.productImg1_appoiment}
                                />
                              </div>
                              <div className={style.div_label_center}>
                                <h3 className={style.h33}>Schedule</h3>
                                <p className={style.pp}>Regular Appointment </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className={style.chooseApp}>
                            <div className={style.card_static_appiment_gray}>
                              <div className={style.div_img}>
                                <img
                                  src="Images/schedule1.png"
                                  alt=""
                                  className={style.productImg_appoiment}
                                />
                              </div>
                              <div className={style.div_label_center}>
                                <h3 className={style.h33}>Schedule</h3>
                                <p className={style.pp}>Regular Appointment</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        {!loader ? (
                          <div className={style.chooseApp}>
                            <div>
                              <div
                                onClick={() => cardClick("callback")}
                                className={`${
                                  selectedCard === "callback"
                                    ? "card_selected_appoiment"
                                    : "card_static_appiment"
                                }`}
                              >
                                <div className={style.div_img}>
                                  <img
                                    src="Images/call-back1.png"
                                    alt=""
                                    className={style.productImg1_appoiment}
                                  />
                                </div>
                                <div className={style.div_label_center}>
                                  <h3 className={style.h33}>
                                    Callback{" "}
                                    {/* {callback.length == 0
                                  ? null
                                  : "(" + callback.length + ")"} */}
                                  </h3>
                                  <p className={style.pp}>
                                    Create Callback Request{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className={style.chooseApp}>
                            <div className={style.card_static_appiment_gray}>
                              <div className={style.div_img}>
                                <img
                                  src="Images/call-back1.png"
                                  alt=""
                                  className={style.productImg_appoiment}
                                />
                              </div>
                              <div className={style.div_label_center}>
                                <h3 className={style.h33}>Callback</h3>
                                <p className={style.pp}>
                                  Create Callback Request
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* )} */}
                    </div>
                  )}
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          footer={null}
          width={1000}
          title="Family Member List"
          visible={isModalVisible}
          onOk={handleOkFamily}
          onCancel={handleCancelFamily}
        >
          {loader ? (
            <div className="spinner">
              <Spin tip="Loading..."></Spin>
            </div>
          ) : (
            // <Table dataSource={familyMember} columns={data} style={{ whiteSpace: 'pre' }} pagination={{ defaultPageSize: 4, showSizeChanger: true, pageSizeOptions: ['5'] }} />
            <div className="row">
              <div className="col-12">
                <Table
                  dataSource={familyMember}
                  columns={data}
                  scroll={{ x: 800 }}
                  rowKey={(obj) => obj.id}
                />
              </div>
            </div>
          )}
        </Modal>

        <Modal
          footer={null}
          title="OHIP card"
          visible={isOhipCardModalVisible}
          onCancel={handleCancelOhip}
          width="1100px"
        >
          {showError ? (
            <Alert
              style={{ marginBottom: "15px", width: "50%" }}
              message={errorMsg}
              type="error"
            />
          ) : null}

          <Form
            form={formOhipCard}
            onFinish={handleOhipSubmit}
            layout="vertical"
          >
            <div className="row">
              <div className="col-lg-8 col-md-6 col-sm-12">
                <div className="row" style={{ marginTop: 20 }}>
                  <>
                    <div className="col-6">
                      <div className="form-group">
                        <Form.Item
                          label="First Name"
                          name="fname"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Firstname!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            name="fname"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <Form.Item
                          label="Middle Name"
                          name="middlename"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Enter Middlename!",
                          //   },
                          // ]}
                        >
                          <Input
                            size="large"
                            name="middlename"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <Form.Item
                          label="Last Name"
                          name="lname"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Lastname!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            name="lname"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <Form.Item
                        label="Date Of Birth"
                        name="birth_date"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Date Of Birth",
                          },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          style={{ width: "100%" }}
                          name="birth_date"
                          onChange={onChangeDateOfBirth}
                          format="YYYY/MM/DD"
                          // disabledDate={(current) => {
                          //   return moment().add(-1, "days") <= current;
                          // }}
                          disabledDate={(current) => {
                            return current && current > moment().endOf("day");
                            // return moment().add(-1, "days") <= current;
                          }}
                          // defaultValue={moment(dateOfBirth, 'YYYY-MM-DD')}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-sm-6">
                      <Form.Item
                        name="gender"
                        label="Gender"
                        rules={[
                          { required: true, message: "Please Select Gender" },
                        ]}
                      >
                        <Select
                          size="large"
                          showSearch
                          placeholder="Select Gender"
                          optionFilterProp="children"
                        >
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                          <Option value="intersex">Other</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <Form.Item
                          label="Ohip Number"
                          name="ohipNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Ohip Number!",
                            },
                            {
                              max: 12,
                              message: "must be maximum 12 characters.",
                            },
                          ]}
                        >
                          {/* <Input
                            type={"number"}
                            name="ohipNumber"
                            size="large"
                            onChange={handleChange}
                          /> */}
                          <MaskInput
                            alwaysShowMask
                            maskChar="_"
                            value="ohipNumber"
                            onChange={handleChange}
                            mask="0000-000-000"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <Form.Item
                          label="Version Code"
                          name="insuranceVersionCode"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Version code!",
                            },
                          ]}
                        >
                          <Input
                            style={{ textTransform: "uppercase" }}
                            size="large"
                            name="insuranceVersionCode"
                            onChange={handleChange}
                            maxLength={2}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className={style.div_card_main}
                  style={{
                    backgroundImage: `url("Images/problems/card.png")`,
                  }}
                >
                  <div>
                    <div
                      className={style.div_card_below}
                      style={{ paddingTop: 0 }}
                    >
                      <div style={{ width: "100%" }}>
                        <label className={style.lbl_card}>Name</label>
                        <div className={style.div_row_name}>
                          <label className={style.lbl_card_name}>
                            {formValues.fname} &nbsp;
                          </label>
                          <label className={style.lbl_card_name}>
                            {formValues.middlename} &nbsp;
                          </label>
                          <label className={style.lbl_card_name}>
                            {formValues.lname}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={style.div_card_below}>
                      <div style={{ width: "67%" }}>
                        <label className={style.lbl_card}>Card Number</label>
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          name="ohipNumber"
                        >
                          <Input
                            size="large"
                            onChange={handleChange}
                            className={style.input_card}
                            disabled={true}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ width: "25%" }}>
                        <label className={style.lbl_card}>Version Code</label>
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          name="insuranceVersionCode"
                        >
                          <Input
                            size="large"
                            onChange={handleChange}
                            className={style.input_card_vcode}
                            disabled={true}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div
                      className={style.div_card_below}
                      style={{ paddingTop: 0 }}
                    >
                      <div style={{ width: "100%" }}>
                        <label className={style.lbl_card}>DOB</label>
                        <div className={style.div_row_name}>
                          <label className={style.lbl_card_name}>
                            {getDateFormated(dateOfBirth)}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="primary"
                loading={btnLoader}
                // onClick={() => handleOk()}
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
      </div>

      {selectAppointment == "Own" ? (
        <div className="footer">
          <Footer
            location="searchDoctor"
            pageName="appoiment-type"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == "null" ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}

      {selectAppointment == "Own" && selectedCard == "fastService" ? (
        <div className="footer">
          <Footer
            location="select-virtualroom"
            pageName="appoiment-type"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == "null" ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}

      {selectAppointment == "familyMember" && selectedCard == "fastService" ? (
        <div className="footer">
          <Footer
            location="select-virtualroom"
            pageName="appoiment-type"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == "null" ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}

      {selectAppointment == "Own" && selectedCard == "Today" ? (
        <div className="footer">
          <Footer
            location="searchDoctor"
            pageName="appoiment-type"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == "null" ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}

      {selectAppointment == "Own" && selectedCard == "TodayPrivatePay" ? (
        <div className="footer">
          <Footer
            location="searchDoctor"
            pageName="appoiment-type"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == "null" ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}

      {selectAppointment == "Own" && selectedCard == "CallbackPrivatePay" ? (
        <div className="footer">
          <Footer
            location="selectPrivateService"
            pageName="appoiment-type"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == "null" ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}
      {selectAppointment == "Own" && selectedCard == "callback" ? (
        <div className="footer">
          <Footer
            location="select-callback"
            // location="selectType"
            pageName="select-callback"
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}

      {/* {selectAppointment == "familyMember" ? (
        <div className="footer">
          <Footer
            location="searchDoctor"
            pageName="appoiment-type"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == "null" ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null} */}
      {selectAppointment == "familyMember" && selectedCard == "Today" ? (
        <div className="footer">
          <Footer
            location="searchDoctor"
            pageName="appoiment-type"
            hide="true"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == null ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}
      {selectAppointment == "familyMember" &&
      selectedCard == "TodayPrivatePay" ? (
        <div className="footer">
          <Footer
            location="searchDoctor"
            pageName="appoiment-type"
            hide="true"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == null ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}

      {selectAppointment == "familyMember" &&
      selectedCard == "CallbackPrivatePay" ? (
        <div className="footer">
          <Footer
            location="selectPrivateService"
            pageName="appoiment-type"
            hide="true"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == null ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}
      {/* {selectAppointment == "familyMember" && selectedCard == "fastService" ? (
        <div className="footer">
          <Footer
            location="searchDoctor"
            pageName="appoiment-type"
            hide="true"
            // disbleFooter={familyMemberState?.ohipNumber == "" || familyMemberState?.ohipNumber == null ? false : true}
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null} */}
      {selectAppointment == "familyMember" && selectedCard == "callback" ? (
        <div className="footer">
          <Footer
            location="select-callback"
            // location="selectType"
            pageName="select-callback"
            disbleFooter={isSlotSelected}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AppoimentType;
