import React from "react";
import { Link } from "react-router-dom";

const Inactive = () => {
  return (
    <div>
      <div className="container pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-32">
        <img src="Images/account_status.svg" alt="logo" />
        <div className="d-flex flex-column align-items-center">
          <div className="font-weight-bold mb-3">
            Your account has been deactivated
          </div>
          <div className="text-gray-6 font-size-30">
            Please contact Administrator for further inquiry
          </div>
          <Link to="/" className="btn btn-outline-primary width-100">
            Go Back
          </Link>
        </div>
        {/* <div className="font-weight-bold font-size-70 mb-1">404 —</div> */}
      </div>
    </div>
  );
};

export default Inactive;
