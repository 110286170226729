/* eslint-disable import/prefer-default-export */
const GRADIENTS = [
  "linear-gradient(110deg, #53a2ff, #2a387b)",
  "linear-gradient(240deg, #ffaf8a, #ff6263)",
  "linear-gradient(240deg, #e0ec51, #2eb18d)",
  "linear-gradient(242deg, #f49cae, #b05ce2)",
  "linear-gradient(240deg, #8affe7, #5939e0)",
  "linear-gradient(240deg, #fddb92, #09adef)",
  "linear-gradient(240deg, #ffaf8a, #ff6263)",
  "linear-gradient(240deg, #bdd377, #09adef)",
  "linear-gradient(240deg, #0a7dd8, #21c8db)",
  "linear-gradient(240deg, #ff6364, #ff88bc)",
  "linear-gradient(240deg, #55e4ef, #c23af1)",
];

export { GRADIENTS };
