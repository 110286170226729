const relationShip = [
  { id: "brother", name: "Brother" },
  { id: "cousin", name: "Cousin" },
  { id: "daughter", name: "Daughter" },
  { id: "father", name: "Father" },
  { id: "grandfather", name: "Grandfather" },
  { id: "grandmother", name: "Grandmother" },
  { id: "husband", name: "Husband" },
  { id: "mother", name: "Mother" },
  { id: "nephew", name: "Nephew" },
  { id: "niece", name: "Niece" },
  { id: "son", name: "Son" },
  { id: "sister", name: "Sister" },
  { id: "uncle", name: "Uncle" },
  { id: "wife", name: "Wife" },
  { id: "other", name: "Other" },
];

const province = [
  { id: "Alberta", name: "Alberta" },
  { id: "British Columbia", name: "British Columbia" },
  // { id: "manitoba", name: "Manitoba" },
  { id: "New Brunswick", name: "New Brunswick" },
  { id: "Newfoundland and Labrador", name: "Newfoundland and Labrador" },
  { id: "Northwest Territories", name: "Northwest Territories" },
  { id: "Nova Scotia", name: "Nova Scotia" },
  { id: "Nunavut", name: "Nunavut" },
  { id: "Ontario", name: "Ontario" },
  { id: "Prince Edward Island", name: "Prince Edward Island" },
  // { id: "Cousin", name: "Cousin" },
  { id: "Quebec", name: "Quebec" },
  { id: "Saskatchewan", name: "Saskatchewan" },
  { id: "Yukon", name: "Yukon" },
].sort((a, b) => {
  return a.name < b.name ? -1 : 1;
});

const doctorNames = [
  { id: "Dr.Harshank", name: "Dr.Harshank" },
  { id: "Dr.BijuBhai", name: "Dr.BijuBhai" },
  { id: "Dr.Darshit", name: "Dr.Darshit" },
  { id: "Dr.Priyanka", name: "Dr.Priyanka" },
  { id: "Dr.Sneha", name: "Dr.Sneha" },
];

const patientGender = [
  { id: "male", name: "male" },
  { id: "female", name: "female" },
  { id: "intersex", name: "intersex" },
];

export { relationShip, province, doctorNames, patientGender };
