import "styles/app.scss";
import "styles/global.scss";
import React, { useEffect } from 'react';
import { Provider, useDispatch } from "react-redux";
import store from "../src/redux/store";
import { Link } from "react-router-dom";
import VideoContainerWrapper from "./components/videoCalling/VideoContainerWrapper";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, history } from "./redux/store";
import Rating from "../src/components/userRatings/index";
import Home from "pages";

function App(): JSX.Element {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   Hub.listen('auth', (data) => {
  //     const { payload } = data
  //     console.log('A new auth event has happened: ', data)
  //      if (payload.event === 'signIn') {
  //        console.log('a user has signed in!')
  //      }
  //      if (payload.event === 'signOut') {
  //        console.log('a user has signed out!')
  //       //  dispatch(authentication(false));
  //      }
  //   })
  // }, [])
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="App">
          <Home history={history} />
        </div>
      </PersistGate>
      <Rating history={history} />
    </Provider>
  );
}
export { history, Link };
export default App;
