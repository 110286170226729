import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faCoffee } from '@fortawesome/free-solid-svg-icons'

import style from './style.module.css'

const Footerfix = () => {
  return (
    <div className="new">
      <div className="row">       
       <div className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block`} style={{ backgroundColor: "var(--primary-color)", borderLeft:'4px solid #fff'}}></div>
        <div className={`col-lg-12 col-xl-10 `} style={{ backgroundColor: "#F0F0F0 " }}>
          <div className="row">
            <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 ${style.logoDiv}`  } >
            <span className={style.footer_label}>© 2024 ONRx. All rights reserved.</span>
            </div>
            <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 ${style.logoDiv2}`}>
            {/* <span className={style.footer_label2}> <a target="_blank" href="/term-and-conditions" className={style.linkA}>Terms and Conditions | Policy  </a> </span> */}
            </div>
          </div>




          {/* <div className="row">
            <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 ${style.logoDiv2}` }>
              <p className={style.footer_label}>© 2023 ONRx. All rights reserved.</p>
            </div>
            <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 ${style.logoDiv}`}>
              <p className={style.footer_label2}> <a target="_blank" href="/term-and-conditions" className={style.linkA}>Terms and Conditions | Policy </a> </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footerfix
