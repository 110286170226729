import { ActionTypes } from "../constants/action-types";
const intialState = {
  user: [],
  IsProfileComplate: true,
  User_Auth: false,
  cognitoUserData: null,
  familyDoc: null
};

export const userReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_USER:
      return { ...state, user: payload };
    case ActionTypes.FAMILY_DOCTOR:
      return { ...state, familyDoc: payload };
    case ActionTypes.IS_PROFILE_COMPLATE:
      return { ...state, IsProfileComplate: payload };
    case ActionTypes.AUTHENTICATION:
      return { ...state, User_Auth: payload };
    case ActionTypes.SET_COGNITO_USER_DATA:
      return { ...state, cognitoUserData: payload };
    case ActionTypes.USER_TOKEN:
      return { ...state, token: payload };
    default:
      return state;
  }
};
