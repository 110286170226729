import React, { useState } from 'react'
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from 'react-router-dom'
import { notification, Spin } from 'antd';
import { UPDATEDPOST, UPDATED_OPEN_POST } from 'services/common.api';
import style from './style.module.scss'
const ChangePassword = (pros, type) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [resendCode, setResendCode] = useState(false);

  console.log(pros, "prosprospros", pros.type, pros.username)

  const changePassword = async (newPasword, code, setSubmitting) => {
    setSubmitting(false)
    setLoader(true)
    setResendCode(false)

    if (pros.type == 'Email') {

      let dataa = {
        email: pros?.username,
        code: code,
        password: newPasword
      }

      console.log(dataa, "dataaaaaaa")

      let result = await UPDATED_OPEN_POST(`confirmpassword`, dataa)

      console.log(result, "--------pros", pros)

      if (result?.data?.message == 'Password reset seccessfully') {
        history.push("/");
        notification.success({
          message: "Password successfully changed",
        });
        setLoader(false)
      } else {
        setResendCode(true)
        notification.error({
          message: result?.data?.message,
        });
        setLoader(false)
        console.log(result.error);
      }

    } else {

      try {

        let dataa = {
          phoneNumber: pros?.username,
          otp: code,
          password: newPasword
        }


        let result = await UPDATED_OPEN_POST(`changemobilepasssowrd`, dataa)

        console.log(result, "resultresultresultresult")




        if (Object.keys(result.data).length === 0){
          history.push("/");
          notification.success({
            message: "Password successfully changed",
          });
        } else if(result.data.message){

          // setResendCode(true)
          notification.error({
            message: result.data.message,
          });
          console.log(result.data,"error messsage");
        }

      } catch (e) {
        // notification.error({
        //   message: e?.message,
        // });
        console.log(e, "error avi")
      }



    }

    setLoader(false)


    // Auth.forgotPasswordSubmit(pros?.username, code, newPasword)
    //   .then(data => {
    //     console.log(data);
    //     history.push("/");
    //     notification.success({
    //       message: "Password successfully changed",
    //     });
    //     setLoader(false)
    //   })
    //   .catch(err => {
    //     setResendCode(true)
    //     notification.error({
    //       message: err.message,
    //     });
    //     setLoader(false)
    //     console.log(err);
    //   });
  };

  const resendOtp = (pros) => {
    console.log(pros)
    pros.reSendOTP();


    notification.warning({
      message: "Warning",
      duration: 3,
      description: "Please check your email for verification code",
    })
  }

  return (
    <div className='w-100' >
      <Formik
        enableReinitialize={true}
        initialValues={{
          code: "",
          newPasword: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(
            "Code is Required"
          ),
          newPasword: Yup.string()
            .required("Please Enter password")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              "Please choose a stronger password. Try a mix of letters, numbers, and symbols"
            ),
          confirmPassword: Yup.string()
            .required("Please Enter Confirm password")
            .oneOf(
              [Yup.ref("newPasword"), null],
              "Passwords must match"
            ),
        })}
        onSubmit={(formData, { setStatus, setSubmitting }) => {
          console.log("formData: ", formData);
          setSubmitting(false);

          const { newPasword, code } = formData;
          changePassword(newPasword, code, setSubmitting);
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          setFieldValue,
          values,
          handleChange,
          handleBlur,
        }) => {
          return (
            <Form className='w-100 d-flex flex-column justify-content-center align-items-center' >
              <div className="form-group required" style={{ width: "60%", minWidth: "300px"}}>
                {/* <label>Confirmation Code</label> */}
                <Field
                  name="code"
                  type="text"
                  placeholder="Code"
                  autoComplete="off"
                  className={
                    "form-control" +
                    (errors.code && touched.code
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="code"
                  component="div"
                  className="invalid-feedback"
                />
                {
                  resendCode ?
                    <div className='text-right pt-1'>
                      <span className='text-danger' style={{ cursor: 'pointer', textDecoration: "underline" }} onClick={() => resendOtp(pros)}>Resend Verification code</span>
                    </div> : null
                }

              </div>

              <div className="form-group required" style={{ width: "60%", minWidth: "300px"}}>
                {/* <label>New Password</label> */}
                <Field
                  name="newPasword"
                  type="password"
                  autoComplete="off"
                  placeholder="New Password"
                  className={
                    "form-control" +
                    (errors.newPasword && touched.newPasword
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="newPasword"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group required" style={{ width: "60%", minWidth: "300px"}}>
                {/* <label>Confirm Password</label> */}
                <Field
                  name="confirmPassword"
                  type="password"
                  autoComplete="off"
                  placeholder="Confirm Password"
                  className={
                    "form-control" +
                    (errors.confirmPassword &&
                      touched.confirmPassword
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group "style={{ width: "100%", minWidth: "300px"}}>
                {/* <div className="offset-md-3 col-md-6 col-xs-12 d-flex justify-content-center w-100"> */}
                  {

                    loader ? <Spin /> :
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`${style.btnGreen} mt-1`}
                        style={{ width: '60%',  minWidth: "300px",fontSize: 14, marginTop: '10px' }}
                      >
                        Continue
                      </button>
                  }

                </div>
              {/* </div> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  )
}

export default ChangePassword
