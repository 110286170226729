import React, { useEffect, useState } from 'react';
import { TopBar } from "components";
import { Link } from 'react-router-dom';
import DashboardSidebar from '../sidebar/sidebar';
import style from './style.module.scss'
import { Drawer, Table } from 'antd';
import { IMG01 } from "../img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";
import Footer from "components/footerFix";

const Orders = () => {
    const dataSource = [
        {
            bookedbyname: 'Doctor John',
            ApptDate: '22 - 10 -2021',
            Booking_Date: '22 - 10 -2021',
            payment_method: 'Insurance Card',
            Status: 'CONFIRMED'
        },
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'bookedbyname',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true,
            render: text =>
                <>
                    <img
                        style={{ height: 50 }}
                        className="avatar-img rounded-circle"
                        src={IMG01}
                        alt="User"
                    />

                    Dr. {text}
                </>,
        },
        {
            title: 'Appointment Date',
            dataIndex: 'ApptDate',
            key: 'ApptDate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Booking Date',
            dataIndex: 'Booking_Date',
            key: 'Booking_Date',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payment Method',
            dataIndex: 'payment_method',
            key: 'payment_method',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            showOnResponse: true,
            showOnDesktop: true,
        }
    ];

    // const [sidebar, setSidebar] = useState(false);
    // const showSidebar = () => setSidebar(!sidebar);

    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, []);


    return (
        <div className="pb-5">
            <TopBar />
            <div className="box">
                <div className={`${style.toggebtn} d-block d-xl-none`}>
                    <i><FontAwesomeIcon className="mr-1" icon={faEquals} onClick={showDrawer} /></i>
                </div>
                <div className="d-block d-xl-none">
                    <p className="pageInfoM">Billing</p>
                    <h5 className="h5NewM">Dashboard / Billing</h5>
                </div>
                <div className="d-none d-xl-block">
                    <p className="pageInfo">Billing</p>
                    <h5 className="h5New"><Link style={{ color: "white" }} to="/dashboard" >Dashboard</Link> / Billing</h5>
                </div>
            </div>
            <div>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0`} style={{ height: '72vh' }}>
                                < DashboardSidebar />
                            </div>
                            <Drawer closable={false} width={300} placement="left" onClose={onClose} visible={visible} style={{ paddingTop: 0 }}>
                                <div className="widget-profile pro-widget-content pt-0 pb-4">
                                    <div className="profile-info-widget">
                                        <img src="../Images/logo.png" height="50" alt="" />
                                    </div>
                                </div>
                                <DashboardSidebar />
                            </Drawer>
                            <div className="col-lg-12 col-xl-10">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="dependent_center col-sm-6">
                                                <h3 className="card-title">Billing</h3>
                                            </div>
                                            <div className="col-sm-6"></div>
                                        </div>
                                    </div>
                                    <div className="card-body ">
                                        <div className="table-responsive">
                                            <Table dataSource={dataSource} columns={columns}  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div >
    );
};

export default Orders;