import apiClient from "../axios";
import store from 'store'
export function getUser(option, res) {

  store.set('auth_token_refresh', res)

  let query;
  if (option.id) {
    query = `?id=${option.id}`;
  } else if (option.cognitoid) {
    query = `?cognitoid=${option.cognitoid}`;
  } else {
    query = null;
  }
  return apiClient.get(`/user${query}`, { headers: { Authorization: `Bearer ${res}` } })
    // return apiClient.get(`/user${query}`, { headers: { Authorization: idtoken } })
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}
