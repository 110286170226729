/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Skeleton, Card, Avatar, Typography, Button, Input, Divider, Space, Tag } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import style from "./style.module.css";

const { Meta } = Card
const { Title, Text } = Typography

const PersonalHealthHistoryCard = ({ loading, setOfQuestionsAnswers, title }) => {

	var checkboxes1 = [];
	var checkboxDate = [];
	var surgeries = [];
	var hospitalizations = [];
	var allergies = [];
	var drugs = [];

	const [loader, setLoader] = useState(true);

	useEffect(() => {
		console.log(setOfQuestionsAnswers.personalHealthHistory, "Personal Health History===");

		setOfQuestionsAnswers.personalHealthHistory?.forEach((element, i) => {

			if (element.type == "checkboxes" && element.heading == "Childhood illness") {
				checkboxes1 = element.checkboxes?.filter(item => {
					return item.hasOwnProperty("isActive") && item.isActive == true
				})
				element.length = checkboxes1.length
			}

			if (element.type == "checkboxdate") {
				checkboxDate = element.checkboxdate?.filter(item => {
					return item.hasOwnProperty("isActive") && item.isActive == true
				})
				element.length = checkboxDate.length
			}

			if (element.type == "textArea") {
				element.length = 0
			}

			if (element.type == "table" && element.uniqueId == "surgeriesTable") {
				surgeries = element.table?.answer?.filter(item => {
					return item.year !== "" && item.reason !== "" && item.hospital !== "";
				})
				element.table.answer = surgeries
				element.length = surgeries.length
			}

			if (element.type == "table" && element.uniqueId == "otherHospitalizationTable") {
				hospitalizations = element.table?.answer?.filter(item => {
					return item.Year !== "" && item.Reason !== "" && item.Hospital !== "" && item.hospital !== "";
				})
				element.table.answer = hospitalizations
				element.length = hospitalizations.length
			}

			if (element.type == "table" && element.uniqueId == "prescribedDrugsTable") {
				drugs = element.table?.answer?.filter(item => {
					return item.nametheDrug !== "" && item.strength !== "" && item.frequencyTaken !== "";
				})
				element.table.answer = drugs
				element.length = drugs.length
			}

			if (element.type == "table" && element.uniqueId == "allergiesTable") {
				allergies = element.table?.answer?.filter(item => {
					return item.frequencyTaken !== "" && item.nametheDrug !== "" && item.strength !== "";
				})
				element.table.answer = allergies
				element.length = allergies.length
			}

			if (setOfQuestionsAnswers.personalHealthHistory?.length - 1 == i) {
				setLoader(false);
				console.log(setOfQuestionsAnswers.personalHealthHistory)
			}
		});

		if (_.isUndefined(setOfQuestionsAnswers?.personalHealthHistory)) {
			setLoader(false);
		}
	}, []);

	return (
		<>
			<div>
				<div className={style.titleHeader}>Personal Health History</div>
				{
					setOfQuestionsAnswers?.personalHealthHistory?.map((questionAndAnswer, index) => {
						return (
							<div className={style.padd}>
								<div key={index}>
									{questionAndAnswer?.heading &&
										questionAndAnswer?.type === 'checkboxes' &&
										questionAndAnswer?.checkboxes.some(function (el) {
											return el.isActive === true
										}) && <h5 className={style.subtitleHeader}>{questionAndAnswer?.heading}</h5>}
									{questionAndAnswer?.heading &&
										questionAndAnswer?.type === 'checkboxdate' &&
										questionAndAnswer?.checkboxdate.some(function (el) {
											return el.isActive === true
										}) && <h5 className={style.subtitleHeader}>{questionAndAnswer?.heading}</h5>}
									{questionAndAnswer?.heading &&
										questionAndAnswer?.type === 'textarea' &&
										questionAndAnswer?.answer !== '' && <h5 className={style.subtitleHeader}>{questionAndAnswer?.heading}</h5>}

									{questionAndAnswer?.heading && questionAndAnswer?.type === 'table' && questionAndAnswer?.length != 0 && (
										<h5 className={style.subtitleHeader}>{questionAndAnswer?.heading}</h5>
									)}

									{questionAndAnswer?.type === 'checkboxes' && questionAndAnswer?.length !== 0 && (
										<div className={style.divider}>
											<div className="row">
												<div className="col-12">
													<Space size={[8, 16]} wrap>
														{questionAndAnswer?.checkboxes?.map(
															(option) => option?.isActive && (
																<span className={style.tags}>{option.label}</span>
															),
														)}
													</Space>
												</div>
											</div>
										</div>
									)}

									{questionAndAnswer?.type === 'checkboxdate' && questionAndAnswer?.length !== 0 && (
										<div className={style.divider}>
											<div className="row">
												<div className="col-12">
													<Space size={[8, 16]} wrap>
														{questionAndAnswer?.checkboxdate?.map(
															(option) => option?.isActive && (
																<span className={style.bgTags}>
																	{option.label} - {moment(option.checkboxDate).format('ll')}
																</span>
															),
														)}
													</Space>
												</div>
											</div>
										</div>
									)}

									{questionAndAnswer?.type === 'textarea' && questionAndAnswer?.answer != "" && (
										<div className={style.divider}>
											<div className="row">
												<div className="col-12">
													<Text>{questionAndAnswer.answer}</Text>
												</div>
											</div>
										</div>
									)}

									{questionAndAnswer?.type === 'table' && questionAndAnswer?.length !== 0 && (
											<div className="row">
												<div className="col-12">
													<table className="table" style={{fontSize:16}}>
														<thead>
															<tr>
																{![6, 5].includes(index) && (
																	<>
																		<th scope="col">Year</th>
																		<th scope="col">Reason</th>
																		<th scope="col">Hospital</th>
																	</>
																)}
																{index === 6 && (
																	<>
																		<th scope="col">Name the Drug</th>
																		<th scope="col">Reaction You Had</th>
																	</>
																)}
																{index === 5 && (
																	<>
																		<th scope="col">Name the Drug</th>
																		<th scope="col">Strength</th>
																		<th scope="col">Frequency Taken</th>
																	</>
																)}
															</tr>
														</thead>
														<tbody>
															{![6, 5].includes(index) &&
																questionAndAnswer.table.answer.length &&
																questionAndAnswer.table.answer.map((tableData, tableIndex) => (
																	<>
																		<tr>
																			<td>
																				<Text>{tableData.year}</Text>
																			</td>
																			<td>
																				<Text>{tableData.reason}</Text>
																			</td>
																			<td>
																				<Text>{tableData.hospital}</Text>
																			</td>
																		</tr>
																	</>
																))}

															{index === 6 &&
																questionAndAnswer.table.answer.length &&
																questionAndAnswer.table.answer.map((tableData, tableIndex) => (
																	<>
																		<tr>
																			<td>
																				<Text>{tableData.nametheDrug}</Text>
																			</td>
																			<td>
																				<Text>{tableData.reactionYouHad}</Text>
																			</td>
																		</tr>
																	</>
																))}
															{index === 5 &&
																questionAndAnswer.table.answer.length &&
																questionAndAnswer.table.answer.map((tableData, tableIndex) => (
																	<>
																		<tr>
																			<td>
																				<Text>{tableData.nametheDrug}</Text>
																			</td>
																			<td>
																				<Text>{tableData.strength}</Text>
																			</td>
																			<td>
																				<Text>{tableData.frequencyTaken}</Text>
																			</td>
																		</tr>
																	</>
																))}
														</tbody>
													</table>
												</div>
											</div>
										
									)}


									{/* <div className={index !== 5 && index !== 2 ? `card-body` : null}>
								<div className="row ml-4">
									<ul className="mt-n3">
										{questionAndAnswer?.type === 'checkboxes' &&
											questionAndAnswer?.checkboxes?.map(
												(option) =>
													option?.isActive && (
														<>
															<li className="mt-3">
																<strong>{option.label}</strong>
															</li>
														</>
													),
											)}
									</ul>

									<ul className="mt-n3">
										{questionAndAnswer?.type === 'checkboxdate' &&
											questionAndAnswer?.checkboxdate?.map(
												(option) =>
													option?.isActive && (
														<>
															<li className="mt-3">
																<strong>{option.label}</strong>
																<Text className="ml-2">
																	{moment(option.checkboxDate).format('ll hh:mm a')}
																</Text>
															</li>
														</>
													),
											)}
									</ul>

									{questionAndAnswer?.type === 'textarea' && questionAndAnswer?.answer != "" && (
										<div className="row ml-1 mr-3">
											<div>
												<Text className="ml-n4">
													{questionAndAnswer.answer
														? questionAndAnswer.answer
														: // : 'Patient has not filled any information related to this'}
														''}
												</Text>
											</div>
										</div>
									)}

									{questionAndAnswer?.type === 'table' && questionAndAnswer?.length !== 0 && (
										<table className="table table-striped ml-n4">
											<thead>
												<tr>
													{![6, 5].includes(index) && (
														<>
															<th scope="col">Year</th>
															<th scope="col">Reason</th>
															<th scope="col">Hospital</th>
														</>
													)}
													{index === 6 && (
														<>
															<th scope="col">Name the Drug</th>
															<th scope="col">Reaction You Had</th>
														</>
													)}
													{index === 5 && (
														<>
															<th scope="col">Name the Drug</th>
															<th scope="col">Strength</th>
															<th scope="col">Frequency Taken</th>
														</>
													)}
												</tr>
											</thead>
											<tbody>
												{![6, 5].includes(index) &&
													questionAndAnswer.table.answer.length &&
													questionAndAnswer.table.answer.map((tableData, tableIndex) => (
														<>
															<tr>
																<td>
																	<Text className="ml-2">{tableData.year}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.reason}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.hospital}</Text>
																</td>
															</tr>
														</>
													))}

												{index === 6 &&
													questionAndAnswer.table.answer.length &&
													questionAndAnswer.table.answer.map((tableData, tableIndex) => (
														<>
															<tr>
																<td>
																	<Text className="ml-2">{tableData.nametheDrug}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.reactionYouHad}</Text>
																</td>
															</tr>
														</>
													))}
												{index === 5 &&
													questionAndAnswer.table.answer.length &&
													questionAndAnswer.table.answer.map((tableData, tableIndex) => (
														<>
															<tr>
																<td>
																	<Text className="ml-2">{tableData.nametheDrug}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.strength}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.frequencyTaken}</Text>
																</td>
															</tr>
														</>
													))}
											</tbody>
										</table>
									)}

								</div>
							</div> */}
								</div>
							</div>

						)
					})
				}
			</div>

			{/* {setOfQuestionsAnswers?.personalHealthHistory ?
			<Card loading={loader} title={title || null} className="mb-4">
				{console.log(
					'setOfQuestionsAnswers?.personalHealthHistory',
					setOfQuestionsAnswers?.personalHealthHistory,
				)}

				{_.isUndefined(setOfQuestionsAnswers?.personalHealthHistory) && (
					<Text>This patient has not filled up their Health History yet</Text>
				)}
				{!_.isUndefined(setOfQuestionsAnswers?.personalHealthHistory) &&
					setOfQuestionsAnswers?.personalHealthHistory?.map((questionAndAnswer, index) => {
						return (
							<div key={index}>
								{questionAndAnswer?.heading &&
									questionAndAnswer?.type === 'checkboxes' &&
									questionAndAnswer?.checkboxes.some(function (el) {
										return el.isActive === true
									}) && <h5>{questionAndAnswer?.heading}</h5>}
								{questionAndAnswer?.heading &&
									questionAndAnswer?.type === 'checkboxdate' &&
									questionAndAnswer?.checkboxdate.some(function (el) {
										return el.isActive === true
									}) && <h5>{questionAndAnswer?.heading}</h5>}
								{questionAndAnswer?.heading &&
									questionAndAnswer?.type === 'textarea' &&
									questionAndAnswer?.answer !== '' && <h5>{questionAndAnswer?.heading}</h5>}

								{questionAndAnswer?.heading && questionAndAnswer?.type === 'table' && questionAndAnswer?.length != 0 && (
									<h5>{questionAndAnswer?.heading}</h5>
								)}


								{questionAndAnswer?.type === 'checkboxes' && questionAndAnswer?.length !== 0 && (
									<div className="card-body">
										<div className="row ml-4">
											<ul className="mt-n3">
												{questionAndAnswer?.checkboxes?.map(
													(option) =>option?.isActive && (
														<>
															<li className="mt-3">
																<strong>{option.label}</strong>
															</li>
														</>
													),
												)}
											</ul>
										</div>
									</div>
								)}

								{questionAndAnswer?.type === 'checkboxdate' &&  questionAndAnswer?.length !== 0 && (
									<div className="card-body">
										<div className="row ml-4">
											<ul className="mt-n3">
												{questionAndAnswer?.checkboxdate?.map((option) =>
													option?.isActive && (
														<>
															<li className="mt-3">
																<strong>{option.label}</strong>
																<Text className="ml-2">
																	{moment(option.checkboxDate).format('ll hh:mm a')}
																</Text>
															</li>
														</>
													),
												)}
											</ul>
										</div>
									</div>
								)}

								{questionAndAnswer?.type === 'textarea' && questionAndAnswer?.answer != "" && (
									<div className="row ml-4 mb-4">
										<div className="row ml-1 mr-3">
											<Text className="ml-n4">
												{questionAndAnswer.answer
													? questionAndAnswer.answer
													: // : 'Patient has not filled any information related to this'}
													''}
											</Text>
										</div>
									</div>
								)}

								{questionAndAnswer?.type === 'table' && questionAndAnswer?.length !== 0 && (
									<div className="card-body">
										<div className="row ml-4">
										<table className="table table-striped ml-n4">
											<thead>
												<tr>
													{![6, 5].includes(index) && (
														<>
															<th scope="col">Year</th>
															<th scope="col">Reason</th>
															<th scope="col">Hospital</th>
														</>
													)}
													{index === 6 && (
														<>
															<th scope="col">Name the Drug</th>
															<th scope="col">Reaction You Had</th>
														</>
													)}
													{index === 5 && (
														<>
															<th scope="col">Name the Drug</th>
															<th scope="col">Strength</th>
															<th scope="col">Frequency Taken</th>
														</>
													)}
												</tr>
											</thead>
											<tbody>
												{![6, 5].includes(index) &&
													questionAndAnswer.table.answer.length &&
													questionAndAnswer.table.answer.map((tableData, tableIndex) => (
														<>
															<tr>
																<td>
																	<Text className="ml-2">{tableData.year}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.reason}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.hospital}</Text>
																</td>
															</tr>
														</>
													))}

												{index === 6 &&
													questionAndAnswer.table.answer.length &&
													questionAndAnswer.table.answer.map((tableData, tableIndex) => (
														<>
															<tr>
																<td>
																	<Text className="ml-2">{tableData.nametheDrug}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.reactionYouHad}</Text>
																</td>
															</tr>
														</>
													))}
												{index === 5 &&
													questionAndAnswer.table.answer.length &&
													questionAndAnswer.table.answer.map((tableData, tableIndex) => (
														<>
															<tr>
																<td>
																	<Text className="ml-2">{tableData.nametheDrug}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.strength}</Text>
																</td>
																<td>
																	<Text className="ml-2">{tableData.frequencyTaken}</Text>
																</td>
															</tr>
														</>
													))}
											</tbody>
										</table>
										</div>
									</div>
								)}
							
							</div>
						)
					})}
			</Card>
		 : null} */}
		</>
	)
}

export default PersonalHealthHistoryCard
