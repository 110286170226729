import { Storage } from "aws-amplify";

// export async function s3Upload(fileName, file) {

//   const { key } = await Storage.put(fileName, file, {
//     contentType: file.type,
//   })
//   return key
// }


export async function s3Upload(fileName, file) {
  const { key } = await Storage.put(fileName, file, {
    contentType: file.type,
    level : 'public'
  })
  return key
}

export async function s3Get(key) {
  try {
    const url = await Storage.get(key, { expires: 8000 })
    console.log('url: ', url)
    return url
  } catch (error) {
    throw error
  }
}

export async function s3GetPrivate(key) {
  try {
    const url = await Storage.get(key, { level: 'public'})
    console.log('url: ', url)
    return url
  } catch (error) {
    throw error
  }
}
