import { Auth } from 'aws-amplify'
import { useEffect, useSelector } from "react";
import * as moment from 'moment'
import store from 'store'
import jwt_decode from "jwt-decode";
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
const fs = require('fs');

// After Migrate all url we need to put this url to .env
const BASEURL = process.env.REACT_APP_API_URL

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  bucketName : process.env.REACT_APP_BUCKET
}
const axios = require('axios')
// const dispatch = useDispatch();
const GET = (path) => {
  GetRefreshToken();
  return axios.get(`${config.apiUrl}${path}`, {
    headers: { Authorization: store.get('auth_token_refresh') }
  }
  ).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}


const GET_WITHOUT_AUTH = (path, token) => {
  return axios.get(`${config.apiUrl}${path}`, {
    headers: { Authorization: token }
  }
  ).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const POST_WITHOUT_AUTH = (path, body, token) => {
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.post(`${config.apiUrl}${path}`, options.body, { headers: { Authorization: token } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}



const POST = (path, body) => {
  GetRefreshToken();
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.post(`${config.apiUrl}${path}`, options.body, { headers: { Authorization: store.get('auth_token_refresh') } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const POSTWITHOUTREFRESHTOKEN = (path, body) => {
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.post(`${config.apiUrl}${path}`, options.body, { headers: { Authorization: store.get('auth_token_refresh') } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const PUT = (path, body) => {
  GetRefreshToken();
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.put(`${config.apiUrl}${path}`, options.body, { headers: { Authorization: store.get('auth_token_refresh') } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const DELETE = (path) => {
  GetRefreshToken();
  return axios.delete(`${config.apiUrl}${path}`, { headers: { Authorization: store.get('auth_token_refresh') } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const OpenPOST = (path, body) => {
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.post(`${config.apiUrl}${path}`, options.body, {}).catch((error) => {
    console.log(error);
    console.log(error.message);
    if (error.response) {

    }
    if (error.request) {
      if (error.message == "Network Error") {

      }
      throw error
    } else {
      throw error
    }
  })
}

const checkToken = () => {
  var duration = 0
  var tkn = store.get('auth_token_refresh');
  console.log("token", tkn);
  if (tkn) {
    var storedtkn = jwt_decode(store.get('auth_token_refresh'));
    // console.log(storedtkn); 
    const date = moment.unix(storedtkn.exp);
    console.log(date,"expiration time")
    const now = moment(new Date())
    duration = date.diff(now, 'minutes');
    console.log(duration,"durationn")
    if (duration <= 0) {
      console.log("Token Expired", duration)
      return false;
    } else {
      console.log("Ahi ave che");
      return true;
    }
  } else {
    console.log("Ahi ave che false ma");
    return false;
  }

}

const GetRefreshToken = async () => {
  store.set('userauth', true);
  var duration = 0
  try {
    var storedtkn = jwt_decode(store.get('auth_token_refresh'));

    console.log(storedtkn,"here")

    const date = moment.unix(storedtkn.exp);
    const now = moment(new Date())
    duration = date.diff(now, 'minutes');
    
    console.log("DURATION ", duration,date);
    
    var storedRefreshTkn = store.get('refresh_token');


    if (duration <= 0) {
      console.log("TIME OUT ************** ", duration)
      window.location = '/login';
    }

    if (duration <= 15) {
      console.log('TOKEN REFRESHED')
      // const currentSession = await Auth.currentSession()
      // const cognitoUser = await Auth.currentAuthenticatedUser()
      // cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {


      let tknPayload = {
        username : store.get('email'),
        refreshToken : storedRefreshTkn
      }

        try {
          await UPDATED_OPEN_POST("getrefreshtoken", tknPayload).then(async (responseTKN) => {
            console.log("Token Changed", responseTKN);
              var userid = store.get('userid');
              var name = store.get('name');
              var email = store.get('email');
              const payload = {
                "activity": 'REFRESHTOKEN',
                "source": 'PATIENTPORTAL',
                "userid": userid,
                "username": email,
                "notes": '',
                "valueeint": 1,
                "value": 1,
                "valuedesc": 'REFRESHTOKEN',
                "sourceapp": "PATIENTPORTAL",
                "name": name
              }
              console.log(payload);

              // store.set('auth_token_refresh', session?.idToken?.jwtToken);
              // store.set('auth_token_exp', session?.idToken?.payload?.exp);
              store.set('auth_token_refresh', responseTKN?.data?.idToken);
              store.set('auth_token_exp', responseTKN?.data?.expiresIn);

              // try {
              //   await POSTWITHOUTREFRESHTOKEN("analyticsdb", payload).then((response) => {
              //     console.log("ANALYTICS LOGGED - Token Refreshed", response);
              //   });
              // } catch (error) {
              //   console.log("ANALYTICS LOG ERROr", error);
              // }

          });
        } catch (error) {
          console.log("Token Changed ERROr", error);
        }
      
    }
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
}

// From Here New Methods for serverless

const UPDATEDPOST = async (path, body) => {
  console.log(BASEURL,"BASEURL BASEURLBASEURL")
  await GetRefreshToken();
  const options = {
    body: {},
  };
  if (body) {
    options.body = body;
  }
  return axios
    .post(`${BASEURL}${path}`, options.body, {
      headers: { Authorization: `Bearer ${store.get("auth_token_refresh")}` },
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          window.location = "/login";
        }
      }
      if (error.request) {
        throw error;
      } else {
        throw error;
      }
    });
};

const UPDATEDFILEUPLOAD = async (path,data) => {
  await GetRefreshToken();
  const formData = new FormData();
  formData.append('file', data?.file);
  formData.append('bucket', config.bucketName);
  formData.append('location', data?.location);
  return axios
    // .put('https://ntgccssv32.execute-api.ca-central-1.amazonaws.com/dev/'+ config.bucketName+'/' + data?.location+ '/' + data?.fileName, formData, {
      .post(`https://m1wfdbuxee.execute-api.ca-central-1.amazonaws.com/v3/upload`, formData, {
      headers: { 
        // Authorization: `Bearer ${store.get("auth_token_refresh")}`,
        // 'Access-Control-Allow-Origin': '*'
      },
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          window.location = "/login";
        }
      }
      if (error.request) {
        throw error;
      } else {
        throw error;
      }
    });
};

const UPDATED_OPEN_POST = (path, body, token) => {
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.post(`${BASEURL}${path}`, options.body, {}).catch((error) => {
    console.log(error);
    console.log(error.message);
    if (error.response) {
    }
    if (error.request) {
      if (error.message == "Network Error") {
      }
      throw error
    } else {
      throw error
    }
  })
}


const UPDATEDPUT = async (path, body) => {
  await GetRefreshToken();
  const options = {
    body: {},
  };
  if (body) {
    options.body = body;
  }
  return axios
    .put(`${BASEURL}${path}`, options.body, {
      headers: { Authorization: `Bearer ${store.get("auth_token_refresh")}` },
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          window.location = "/login";
        }
      }
      if (error.request) {
        throw error;
      } else {
        throw error;
      }
    });
};


const UPDATED_OPEN_PUT = async (path, body) => {
  await GetRefreshToken();
  const options = {
    body: {},
  };
  if (body) {
    options.body = body;
  }
  return axios
    .put(`${BASEURL}${path}`, options.body)
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          window.location = "/login";
        }
      }
      if (error.request) {
        throw error;
      } else {
        throw error;
      }
    });
};


const UPDATEDGET = async (path, body) => {
  await GetRefreshToken();
  return axios
    .get(`${BASEURL}${path}`, {
      headers: { Authorization: `Bearer ${store.get("auth_token_refresh")}` },
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          window.location = "/login";
        }
      }
      if (error.request) {
        throw error;
      } else {
        throw error;
      }
    });
};


const UPDATED_OPEN_GET = async (path, body) => {
  await GetRefreshToken();
  return axios
    .get(`${BASEURL}${path}`)
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          window.location = "/login";
        }
      }
      if (error.request) {
        throw error;
      } else {
        throw error;
      }
    });
};


const UPDATEDDELETE = async (path, body) => {
  await GetRefreshToken();
  return axios
    .delete(`${BASEURL}${path}`, {
      headers: { Authorization: `Bearer ${store.get("auth_token_refresh")}` },
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          window.location = "/login";
        }
      }
      if (error.request) {
        throw error;
      } else {
        throw error;
      }
    });
}

export { checkToken, GET, POST, PUT, DELETE, OpenPOST, GET_WITHOUT_AUTH, POST_WITHOUT_AUTH, UPDATEDPOST, UPDATEDFILEUPLOAD, UPDATEDPUT, UPDATEDGET, UPDATEDDELETE, UPDATED_OPEN_POST, UPDATED_OPEN_GET, UPDATED_OPEN_PUT }
