import moment from "moment";
const pdfContentType = "application/pdf";

const base64toBlob = (data) => {
  const bytes = atob(data);
  let { length } = bytes;
  const out = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: pdfContentType });
};

const setStartTimeBasedOnCallBackRequest = (callBackType, time) => {
  if (time == "start") {
    console.log("callBackType: ", callBackType);
    if (callBackType == "Morning") {
      return moment().format("YYYY-MM-DD 08:00:00");
    }
    if (callBackType == "Afternoon") {
      return moment().format("YYYY-MM-DD 12:00:00");
    }
    if (callBackType == "Evening") {
      return moment().format("YYYY-MM-DD 17:00:00");
    }
  }

  if (time == "end") {
    console.log("callBackType: ", callBackType);
    if (callBackType == "Morning") {
      return moment().format("YYYY-MM-DD 12:00:00");
    }
    if (callBackType == "Afternoon") {
      return moment().format("YYYY-MM-DD 17:00:00");
    }
    if (callBackType == "Evening") {
      return moment("17:00:00", "HH:mm:ss").add(7, "h").format("YYYY-MM-DD HH:mm:ss");
    }
  }
};

const setStartTimeBasedOnCallBackRequestWithDate = (callBackType, time, date) => {
  if (time == "start") {
    console.log("callBackType: ", callBackType);
    if (callBackType == "Morning") {
      let A = `${moment(date, "dddd, MMM DD, YYYY").format("YYYY-MM-DD")} 08:00 AM`
      console.log(A, "A START")
      return A
    }
    if (callBackType == "Afternoon") {
      let A = `${moment(date, "dddd, MMM DD, YYYY").format("YYYY-MM-DD")} 12:00 PM`
      return A
    }
    if (callBackType == "Evening") {
      let A = `${moment(date, "dddd, MMM DD, YYYY").format("YYYY-MM-DD")} 05:00 PM`
      console.log(A, "A START")
      return A
    }
  }

  if (time == "end") {
    console.log("callBackType: ", callBackType);
    if (callBackType == "Morning") {
      let A = `${moment(date, "dddd, MMM DD, YYYY").format("YYYY-MM-DD")} 12:00 PM`
      return A
    }
    if (callBackType == "Afternoon") {
      let A = `${moment(date, "dddd, MMM DD, YYYY").format("YYYY-MM-DD")} 05:00 PM`
      return A
    }
    if (callBackType == "Evening") {
      let A = `${moment(date, "dddd, MMM DD, YYYY").format("YYYY-MM-DD")} 09:00 AM`
      console.log(A, "A END")
      return A
    }
  }
};

const setStartTimeBasedOnCallBackRequestWithDateAndTime = (timeRange, from, date) => {
  console.log(timeRange,date,"date timerange",from)
  const [startTimeStr, endTimeStr] = timeRange.split("-");
  const dateMoment = moment(date, "dddd, MMM DD, YYYY");

  const startTime = moment(`${dateMoment.format("YYYY-MM-DD")} ${startTimeStr}`, "YYYY-MM-DD h:mmA");
  const endTime = moment(`${dateMoment.format("YYYY-MM-DD")} ${endTimeStr}`, "YYYY-MM-DD h:mmA");


  const callbackstart = startTime.format("YYYY-MM-DD h:mm A");
  const callbackend = endTime.format("YYYY-MM-DD h:mm A");


  if(from == "end"){
    return callbackend
  }else{
    return callbackstart
  }

};

export { pdfContentType, base64toBlob, setStartTimeBasedOnCallBackRequest, setStartTimeBasedOnCallBackRequestWithDate, setStartTimeBasedOnCallBackRequestWithDateAndTime };
