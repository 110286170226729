import { useState, useEffect } from "react";
import { TopBar } from "components";
import DashboardSidebar from "../sidebar/sidebar";
import { Drawer, Form, Input, Button, Select, notification, Spin } from "antd";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";
import Footer from "components/footerFix";
import { useSelector } from "react-redux";
import { UPDATEDGET, UPDATEDPOST, UPDATEDPUT } from "services/common.api";
import PageHeader from "components/pageHeader";

const { Option } = Select;

const SecurityPin = () => {
  const userData = useSelector((state: any) => state.userReducer.user);
  const [form] = Form.useForm();
  const [showFirstDropdownText, setShowFirstDropdownText] = useState(false);
  const [showSecondDropdownText, setShowSecondDropdownText] = useState(false);
  const [showThirdDropdownText, setShowThirdDropdownText] = useState(false);
  const [firstQuestions, setFirstQuestions] = useState([] as any)
  const [secondQuestions, setSecondQuestions] = useState([] as any)
  const [thirdQuestions, setThirdQuestions] = useState([] as any)
  const [usedQuestion, setUsedQuestion] = useState([] as any)
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleFirstDropdownChange = (value) => {
    setShowFirstDropdownText(!!value);
  };

  const handleSecondDropdownChange = (value) => {
    setShowSecondDropdownText(!!value);
  };

  const handleThirdDropdownChange = (value) => {
    setShowThirdDropdownText(!!value);
  };

  useEffect(() => {
    console.log(userData, "userData userData")
    isSecure()
  }, [userData])

  const isSecure = async () => {
    const questions = await UPDATEDGET('randomquestion');
    console.log(questions?.data, "questions")
    setFirstQuestions(questions?.data?.group1)
    setSecondQuestions(questions?.data?.group2)
    setThirdQuestions(questions?.data?.group3)

    if (userData?.isSecure === 1) {
      const usedQuestion = await UPDATEDGET(`user/securityquestion?id=${userData?.id}`)

      setUsedQuestion(usedQuestion?.data)
    }
  }

  const addSecurityPin = async (event) => {
    setLoader(true)

    console.log(event, "event")

    if (userData?.isSecure === 0) {
      let item = []

      item.push({ question_id: parseInt(event?.firstDropdown), answer: event?.firstDropdownText }, { question_id: parseInt(event?.secondDropdown), answer: event?.secondDropdownText }, { question_id: parseInt(event?.thirdDropdown), answer: event?.thirdDropdownText })

      const finalData = {
        user_id: userData?.id, // Assuming the user_id is available in userData
        item,
      };

      try {
        const result = await UPDATEDPOST('user/securityquestion', finalData).then(async (response) => {

          const data = {
            security_pin: event?.security_pin,
            isSecure: 1
          }

          console.log(finalData, "finalDatafinalData")
          const updateSecuritypin = await UPDATEDPUT(`user/patient/profile/${userData.cognitoid}`, data).then((res) => {
            setLoader(false)
            setShowFirstDropdownText(false)
            setShowSecondDropdownText(false)
            setShowThirdDropdownText(false)
            form.resetFields();
            notification.success({
              message: 'Security pin added successfully.',
            })
          });
          console.log(updateSecuritypin, "updateSecuritypin")
        })
      } catch (error) {
        setLoader(false)
        setShowFirstDropdownText(false)
        setShowSecondDropdownText(false)
        setShowThirdDropdownText(false)
        console.log(error, "error")
        notification.error({
          message: 'Error while while add security pin.',
        })
      }

    } else {

      console.log(usedQuestion,"usedQuestion")

      let obj = []

      usedQuestion.forEach((element, index) => {
        if(index == 0){
          obj.push({
            question_id : event.firstDropdown,
            answer : event.firstDropdownText,
            id : element.id
          })
        }
        if(index == 1){
          obj.push({
            question_id : event.secondDropdown,
            answer : event.secondDropdownText,
            id : element.id
          })
        }
        if(index == 2){
          obj.push({
            question_id : event.thirdDropdown,
            answer : event.thirdDropdownText,
            id : element.id
          })
        }
      });

      console.log(obj,"obj")

      const finalData = {
        user_id: userData?.id, // Assuming the user_id is available in userData
        obj,
      };

      console.log(finalData,"finalData")


      const data = {
        security_pin: event?.security_pin,
        isSecure: 1
      }

      console.log(data,"data")

      try{

      const updateSecuritypin = await UPDATEDPUT(`user/patient/profile/${userData.cognitoid}`, data).then(async (res) => {
       const result = await UPDATEDPUT(`users/securityquestion`,finalData)
       setLoader(false)
       setShowFirstDropdownText(false)
       setShowSecondDropdownText(false)
       setShowThirdDropdownText(false)
        form.resetFields();
        notification.success({
          message: 'Security pin update successfully.',
        })
      });

    } catch (error) {
      setLoader(false)
      setShowFirstDropdownText(false)
      setShowSecondDropdownText(false)
      setShowThirdDropdownText(false)
      console.log(error, "error")
      notification.error({
        message: 'Error while while add security pin.',
      })
    }
    }
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={`${style.mainDiv}`}>
      <TopBar onShow={showDrawer} />
      
        <div className="content" style={{ height: '100%' }}>
          <div className="container-fluid" style={{ height: '100%' }}>
            <div className="row" style={{ height: '100%' }}>
              <div
                className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0`}
                style={{ height: "100%" }}
              >
                <DashboardSidebar />
              </div>
              <Drawer
                closable={false}
                width={300}
                placement="left"
                visible={visible}
                onClose={onClose}
                style={{ paddingTop: 0 }}
              >
                <div className="widget-profile pro-widget-content pt-0 pb-4">
                  <div className="profile-info-widget">
                    <img src="../Images/logo.png" height="50" alt="" />
                  </div>
                </div>
                <DashboardSidebar />
              </Drawer>
              <div className="col-lg-12 col-xl-10">
              <PageHeader
                  items={[
                    { label: "Dashboard", link: "/dashboard" },
                    { label: "Security Pin", link: "" },
                  ]}
                  pageTitle="Security Pin"
                />
                <div className="card">
                  <div className="card-body">
                    <Form layout="vertical"
                      form={form}
                      onFinish={addSecurityPin}
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-xl-6">
                          <Form.Item
                            name="security_pin"
                            label="Set Security PIN"
                            rules={[
                              {
                                required: true,
                                pattern: /^\d{6}$/,
                                message: 'Please enter a 6-digit number.',
                              },
                            ]}
                          >
                            <Input.Password placeholder="Secret PIN" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-xl-6">
                          <Form.Item
                            name="firstDropdown"
                            label="First Dropdown"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select an option"
                              onChange={handleFirstDropdownChange}
                            >
                              <Option value="">Select an option</Option>
                              {firstQuestions.length
                                ? firstQuestions.map((question, index) => (
                                  <Option
                                    key={index}
                                    value={`${question?.id}`}
                                  >
                                    {question?.question}
                                  </Option>
                                ))
                                : ""}
                            </Select>
                          </Form.Item>
                          {showFirstDropdownText && (
                            <Form.Item
                              name="firstDropdownText"
                              label="First Dropdown Text"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input placeholder="Enter text" />
                            </Form.Item>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-xl-6">
                          <Form.Item
                            name="secondDropdown"
                            label="Second Dropdown"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select an option"
                              onChange={handleSecondDropdownChange}
                            >
                              <Option value="">Select an option</Option>
                              {secondQuestions.length
                                ? secondQuestions.map((question, index) => (
                                  <Option
                                    key={index}
                                    value={`${question?.id}`}
                                  >
                                    {question?.question}
                                  </Option>
                                ))
                                : ""}
                            </Select>
                          </Form.Item>
                          {showSecondDropdownText && (
                            <Form.Item
                              name="secondDropdownText"
                              label="Second Dropdown Text"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input placeholder="Enter text" />
                            </Form.Item>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-xl-6">
                          <Form.Item
                            name="thirdDropdown"
                            label="Third Dropdown"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select an option"
                              onChange={handleThirdDropdownChange}
                            >
                              <Option value="">Select an option</Option>
                              {thirdQuestions.length
                                ? thirdQuestions.map((question, index) => (
                                  <Option
                                    key={index}
                                    value={`${question?.id}`}
                                  >
                                    {question?.question}
                                  </Option>
                                ))
                                : ""}
                            </Select>
                          </Form.Item>
                          {showThirdDropdownText && (
                            <Form.Item
                              name="thirdDropdownText"
                              label="Third Dropdown Text"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input placeholder="Enter text" />
                            </Form.Item>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-3 col-md-3 col-xl-3">
                          {/* <div className="form-group text-center mb-0 m-t-20"> */}
                             {loader ? (
                                  <div className="spinner">
                                    {/* <Spin tip="Loading..."></Spin> */}
                                    <Spin tip="Loading ..."></Spin>
                                  </div>
                                ) : (
                                  <button type="submit" className="btn">
                                   Confirm
                                  </button>
                                )}
                          {/* </div> */}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     

      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default SecurityPin;
