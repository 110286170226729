import { ActionTypes } from "../constants/action-types";

const intialState = {
};

export const curentAppointment = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.CURRENT_APPOINTMENT:
      return { ...state, paymentProvider: payload };
    case ActionTypes.SET_PROBLEMS:
      return { ...state, problem: payload };
    case ActionTypes.SELECT_PAYMENT_PROVIDER:
      return { ...state, paymentProvider: payload };
    case ActionTypes.APPOINTMENT_TYPE:
      return { ...state, appointmentType: payload };
    case ActionTypes.BOOK_APPOINTMENT_DOCTOR:
      return { ...state, bookAppointmentDoctor: payload };
    case ActionTypes.BOOK_APPOINTMENT:
      return { ...state, selectedService: payload };
    case ActionTypes.CALLBACK_TYPE:
      return { ...state, callback: payload };
    case ActionTypes.WHICH_VERSION:
      return { ...state, whichVersion: payload };
    case ActionTypes.SELECTED_FAMILY_MEMBER:
      return { ...state, familyMember: payload };
    case ActionTypes.APPOINTMENT_REQUEST_TYPE:
      return { ...state, appointmentRequestType: payload };
    case ActionTypes.INSURANCE:
      return { ...state, Insurance: payload };
    default:
      return state;
  }
};



