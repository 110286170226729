import { useEffect, useState } from "react";
import { TopBar } from "components";
import Footer from "components/footer";
import { Steps, Form, Button, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  callbackAppointment,
  selectAppointmentType,
} from "../../redux/actions/userActions";
import style from "./style.module.css";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { UPDATEDGET } from "services/common.api";
import PageHeader from "components/pageHeader";

const SelectPrivateService = () => {
  const dispatch = useDispatch();
  const { Step } = Steps;
  const [selectedCard, setSelectedCard] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [tomorrowDate, setTomorrowDate] = useState("");
  const [certainHours, setCertainHours] = useState("");
  const [reason, setReason] = useState("");
  const [slots, setSlots] = useState(false);
  const [slotsTime, setSlotsTime] = useState("");
  const { Option } = Select;
  const [selectedServiceValue, setSelectedServiceValue] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [allServices, setAllServices] = useState([]);
  const [slotsToShowTime, setSlotsToShow] = useState([]);
  const [slotsCard, setSlotsCard] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const [addText, setAddText] = useState(false)
  const [vitalTextValue,setVitalTextValue] = useState("")


  let delayedTime = moment().add(3, "hours");

  console.log('selectedServiceValue____',selectedServiceValue);
  console.log('selectedService____',selectedService);
  console.log('vitalTextValue)_)_',vitalTextValue)
  
  
  // const slotIntervals = [
  //     { id: '8:00AM-10:00AM', label: '8:00AM-10:00AM', imageSrc: 'Images/morning.png' },
  //     { id: '10:00AM-12:00PM', label: '10:00AM-12:00PM', imageSrc: 'Images/morning.png' },
  //     { id: '12:00PM-2:00PM', label: '12:00PM-2:00PM', imageSrc: 'Images/afternoon.png' },
  //     { id: '2:00PM-4:00PM', label: '2:00PM-4:00PM', imageSrc: 'Images/afternoon.png' },
  //     { id: '4:00PM-6:00PM', label: '4:00PM-6:00PM', imageSrc: 'Images/evening.png' },
  //     { id: '6:00PM-8:00PM', label: '6:00PM-8:00PM', imageSrc: 'Images/evening.png' },
  // ];
  const slotIntervals = [
    // {
    //   id: "8:00AM-10:00AM",
    //   label: "8:00AM-10:00AM",
    //   imageSrc: "Images/purple-calender.png",
    // },
    {
      id: "10:00AM-12:00PM",
      label: "10:00AM-12:00PM",
      imageSrc: "Images/purple-calender.png",
    },
    {
      id: "12:00PM-2:00PM",
      label: "12:00PM-2:00PM",
      imageSrc: "Images/purple-calender.png",
    },
    {
      id: "2:00PM-4:00PM",
      label: "2:00PM-4:00PM",
      imageSrc: "Images/purple-calender.png",
    },
    {
      id: "4:00PM-6:00PM",
      label: "4:00PM-6:00PM",
      imageSrc: "Images/purple-calender.png",
    },
    // {
    //   id: "6:00PM-8:00PM",
    //   label: "6:00PM-8:00PM",
    //   imageSrc: "Images/purple-calender.png",
    // },
  ];

  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  console.log("CUUCUCUCUC____UCUC",currentHour, "currentTime", currentTime);
  

  const cardClick = (selectedCard, day) => {
    if (day == "today") {
      setIsToday(true);
      // setTodayDate(moment().format("dddd, MMM DD, YYYY"))
    } else {
      setIsToday(false);
      // setTodayDate(moment(1,'days').format("dddd, MMM DD, YYYY"))
    }
    console.log(selectedCard, "id");
    setSlotsTime(selectedCard);
    setSelectedCard(selectedCard);
    setSlotsCard(true);
    // dispatch(callbackAppointment(selectedCard));
    // if (selectedCard == "Morning") {
    //     setSlotsTime("08:00AM-12:00PM")
    // } else if (selectedCard == "Afternoon") {
    //     setSlotsTime("12:00PM-05:00PM")
    // } else if (selectedCard == "Evening") {
    //     setSlotsTime("05:00PM-09:00PM")
    // }
  };

  useEffect(() => {
    console.log("Date", moment().format("dddd, MMM DD, YYYY"));
    setTodayDate(moment().format("dddd, MMM DD, YYYY"));
    setTomorrowDate(moment().add(1, "days").format("dddd, MMM DD, YYYY"));
    console.log(
      "Tomorrow Date--->",
      moment().add(1, "days").format("dddd, MMM DD, YYYY")
    );
    // dispatch(callbackAppointment(selectedCard));
    generateGreetings();
    getServices();
  }, []);

  const getServices = async () => {
    try {
      await UPDATEDGET(`privateservices`).then((response) => {
        console.log("All Services", response.data);

        if (response?.data?.errorMessage) {
          setAllServices([]);
        } else {
          setAllServices(response?.data);
        }
      });
    } catch (error) {
      setAllServices([]);
      console.log(error, "error");
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('Change:', e.target.value);
    setVitalTextValue(e.target.value)
    setAddText(true)
  };

  // const generateGreetings = () => {
  //     console.log("okokokokokokokokok ")
  //     let currentHour: any = moment().format("HH");
  //     if (currentHour >= 3 && currentHour < 12) {
  //         setCertainHours("Morning")
  //         console.log(currentHour, "event****")
  //         setSlots(false)
  //         setSlotsTime("08:00AM-12:00PM")

  //     } else if (currentHour >= 12 && currentHour < 15) {
  //         setCertainHours("Afternoon")
  //         setSelectedCard('Afternoon')
  //         setSlotsTime("12:00PM-05:00PM")
  //         console.log(currentHour, "event****")
  //         setSlots(false)

  //     } else if (currentHour >= 15 && currentHour < 20) {
  //         setCertainHours("Evening")
  //         console.log(currentHour, "event****")
  //         setSelectedCard('Evening')
  //         setSlotsTime("05:00PM-09:00PM")
  //         setSlots(false)
  //     } else {
  //         setSlots(true)
  //         console.log("NO slots available")
  //     }
  // }

  const generateGreetings = () => {
    let currentHour: any = moment().format("HH");
    // let currentHour: any = 23;

    let currentTime: any = moment().format("HH:mmA");
    // let currentTime = '11:00AM';
    let slotsToShow = slotIntervals.filter((slot) => {
      let slotStartTime: any = moment(slot.id.split("-")[0], "h:mmA").format(
        "HH:mmA"
      );
      // if (
      //   moment(slotStartTime, "HH:mmA").isSameOrAfter(
      //     moment(currentTime, "HH:mmA").add(1, "hours")
      //   )
      // ) {
      //   return slot;
      // }

      if (
        moment(slotStartTime, "HH:mmA").isSameOrAfter(
          moment(currentTime, "HH:mmA")
        )
      ) {
        return slot;
      }
    });

    if (slotsToShow.length == 0) {
      setSlotsCard(false);
    }

    console.log(slotsToShow.length, "slotsToShow");

    setSlotsToShow(slotsToShow);

    if (currentHour >= 8 && currentHour < 10) {
      setCertainHours("Morning");
      console.log(currentHour, "event****");
      setSlots(false);
      setSlotsTime("8:00AM-10:00AM");
    } else if (currentHour >= 10 && currentHour < 12) {
      setCertainHours("Morning");
      console.log(currentHour, "event****");
      setSlots(false);
      setSlotsTime("10:00AM-12:00PM");
    } else if (currentHour >= 12 && currentHour < 14) {
      setCertainHours("Afternoon");
      // setSelectedCard('Afternoon');
      setSlotsTime("12:00PM-2:00PM");
      console.log(currentHour, "event****");
      setSlots(false);
    } else if (currentHour >= 14 && currentHour < 16) {
      setCertainHours("Afternoon");
      // setSelectedCard('Afternoon');
      setSlotsTime("2:00PM-4:00PM");
      console.log(currentHour, "event****");
      setSlots(false);
    } else if (currentHour >= 16 && currentHour < 18) {
      setCertainHours("Evening");
      console.log(currentHour, "event****");
      // setSelectedCard('Evening');
      setSlotsTime("4:00PM-6:00PM");
      setSlots(false);
    } else if (currentHour >= 18 && currentHour < 20) {
      setCertainHours("Evening");
      console.log(currentHour, "event**** 6");
      // setSelectedCard('Evening');
      setSlotsTime("6:00PM-8:00PM");
      setSlots(false);
    } else {
      setSlots(true);
      console.log("NO slots available");
    }
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const leftArraow = () => {
    setSlotsCard(false);
    let C = moment().format("dddd, MMM DD, YYYY");
    setTodayDate(moment(C).format("dddd, MMM DD, YYYY"));
    generateGreetings();
  };
  const rightArraow = () => {
    let C = moment().format("DD-MM-YYYY");
    let A = moment(C, "DD-MM-YYYY").add(1, "days");
    console.log(moment(A).format("dddd, MMM DD, YYYY"));
    setTodayDate(moment(A).format("dddd, MMM DD, YYYY"));
    setSlots(false);
  };

  function handleChangeService(value) {
    console.log(value, "selected____services");
    setSelectedServiceValue(value);
    const data = allServices?.filter((item) => item.id == value);
    console.log(data[0], "sleectedData[0]");
    setSelectedService(data[0]);
  }

  return (
    <div style={{ height: "100%" }}>
      <TopBar isBookAppointment={true} />

      <div className="content contentSelect" style={{ height: 600 }}>
        <div className="container-fluid">
          <PageHeader
            items={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Select Private Service", link: "" },
            ]}
            pageTitle="Private Service"
          />
          {/* <div className="row">
            <div className="col-sm-0 col-md-1 col-xl-2"></div>
            <div className="col-sm-12 col-md-10 col-xl-8"> */}
          {/* <div className="stepDiv"> */}
          {/* <Steps> */}
          {/* <Step status="wait" title="Problems" /> */}
          {/* <Step status="finish" title="Select Slot" />
                                    <Step status="finish" title="Select Service" />
                                    <Step status="finish" title="Payment" /> */}
          {/* <Step status="wait" title="Select Doctor" /> */}
          {/* <Step status="wait" title="Book Appointment" /> */}
          {/* </Steps> */}
          {/* <div className={style.divPayment}>
                  {todayDate !== moment().format("dddd, MMM DD, YYYY") ? (
                    <img
                      onClick={leftArraow}
                      src="Images/problems/arrow-left.png"
                      alt=""
                      className={style.arrowIcon}
                    />
                  ) : null}
                  <h2 className="lblPayment px-3">{todayDate}</h2>
                  {todayDate == moment().format("dddd, MMM DD, YYYY") ? (
                    <img
                      onClick={rightArraow}
                      src="Images/problems/arrow-right.png"
                      alt=""
                      className={style.arrowIcon}
                    />
                  ) : null}
                </div> */}
          {/* </div> */}
          {/* </div>
            <div className="col-sm-0 col-md-1 col-xl-2"></div>
          </div> */}

          {/* <div className="row">
                        <div className="col-12">
                            <div className="row text-left">
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                                <div className="col-sm-10 col-md-10 col-xl-8">
                                    <div className={style.div_flex}>
                                        {
                                            certainHours == 'Morning' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div onClick={() => cardClick('Morning')} className={`${selectedCard === 'Morning' ? `${style.card_selected}` : `${style.card_static}`}`}>
                                                            <img src="Images/morning.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Morning</h4>
                                                                <h6 className="p-0 m-0">8AM - 12PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        {
                                            todayDate !== moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div onClick={() => cardClick('Morning')} className={`${selectedCard === 'Morning' ? `${style.card_selected}` : `${style.card_static}`}`}>
                                                            <img src="Images/morning.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Morning</h4>
                                                                <h6 className="p-0 m-0">8AM - 12PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            certainHours == 'Afternoon' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        {
                                            certainHours == 'Evening' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            slots == true ?
                                                <div className={style.div_inner_flex}>
                                                    <div className={style.card_static}>
                                                        <img src="Images/problems/cancel.png" alt="" className={style.productImg} />
                                                        <div className="doc-info-center text-center">
                                                            <h4 className="p-0 m-0 pt-2">No slots available</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                            </div>
                        </div>
                    </div> */}

          <div className="row mt-5">
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <h2 className="lblPayment px-3 d-flex justify-content-center">
                {todayDate}
              </h2>

              <div className={style.divPayment}>
                {slotsToShowTime.length === 0 &&
                  todayDate === moment().format("dddd, MMM DD, YYYY") ? (
                  <div className={style.div_inner_flex}>
                    <div className={style.card_static}>
                      <img
                        src="Images/problems/recorsss.webp"
                        alt=""
                        className={style.productImg}
                      />
                      <div className="doc-info-center text-center">
                        <h4 className="p-0 m-0 pt-2" style={{ color: "red" }}>
                          No slots available
                        </h4>
                      </div>
                    </div>
                  </div>
                ) : (

                  <>
                  {/* {currentHour > 9 && currentHour < 17 ? slotsToShowTime.map((slot) => (
                    <div className={style.div_inner_flex} key={slot.id}>
                      <div
                        className={`${slotsTime === slot.id && isToday == true
                            ? `${style.card_selected}`
                            : `${style.card_static}`
                          }`}
                        onClick={() => cardClick(slot.id, "today")}
                      >
                        <div className="doc-info-center text-center">
                          <h4 className="p-0 m-0">
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "var(--primary-color)",
                              }}
                            >
                              Book
                            </span>
                          </h4>
                        </div>
                        <img
                          src={slot.imageSrc}
                          alt=""
                          className={style.productImg}
                        />
                        <div className="doc-info-center text-center">
                          <h4
                            className="p-0 m-0"
                            style={{
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {slot.label}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )) : <div className={style.div_inner_flex}>
                  <div className={style.card_static}>
                    <img
                      src="Images/problems/recorsss.webp"
                      alt=""
                      className={style.productImg}
                    />
                    <div className="doc-info-center text-center">
                      <h4 className="p-0 m-0 pt-2" style={{ color: "red" }}>
                        No slots available
                      </h4>
                    </div>
                  </div>
                </div> } */}
                  { slotsToShowTime.map((slot) => (
                    <div className={style.div_inner_flex} key={slot.id}>
                      <div
                        className={`${slotsTime === slot.id && isToday == true
                            ? `${style.card_selected}`
                            : `${style.card_static}`
                          }`}
                        onClick={() => cardClick(slot.id, "today")}
                      >
                        <div className="doc-info-center text-center">
                          <h4 className="p-0 m-0">
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "var(--primary-color)",
                              }}
                            >
                              Book
                            </span>
                          </h4>
                        </div>
                        <img
                          src={slot.imageSrc}
                          alt=""
                          className={style.productImg}
                        />
                        <div className="doc-info-center text-center">
                          <h4
                            className="p-0 m-0"
                            style={{
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {slot.label}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))  }
                  </>

                  
                )}
              </div>

              <h2 className="lblPayment px-3 d-flex justify-content-center">
                {tomorrowDate}
              </h2>
              <div className={style.divPayment}>
                {slotsToShowTime.length === 0 &&
                  todayDate !== moment().format("dddd, MMM DD, YYYY") ? (
                  <div className={style.div_inner_flex}>
                    <div className={style.card_static}>
                      <img
                        src="Images/problems/recorsss.webp"
                        alt=""
                        className={style.productImg}
                      />
                      <div className="doc-info-center text-center">
                        <h4 className="p-0 m-0 pt-2" style={{ color: "red" }}>
                          No slots available
                        </h4>
                      </div>
                    </div>
                  </div>
                ) : (

                  <>
                  {/* {currentHour > 9 && currentHour < 17 ? slotIntervals.map((slot) => (
                    <div className={style.div_inner_flex} key={slot.id}>
                      <div
                        className={`${slotsTime === slot.id && isToday == false
                            ? `${style.card_selected}`
                            : `${style.card_static}`
                          }`}
                        onClick={() => cardClick(slot.id, "tomorrow")}
                      >
                        <div className="doc-info-center text-center ">
                          <h4 className="p-0 m-0">
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "var(--primary-color)",
                              }}
                            >
                              Book{" "}
                            </span>
                          </h4>
                        </div>

                        <img
                          src={slot.imageSrc}
                          alt=""
                          className={style.productImg}
                        />
                        <div className="doc-info-center text-center">
                          <h4
                            className="p-0 m-0"
                            style={{
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {slot.label}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )) : <div className={style.div_inner_flex}>
                  <div className={style.card_static}>
                    <img
                      src="Images/problems/recorsss.webp"
                      alt=""
                      className={style.productImg}
                    />
                    <div className="doc-info-center text-center">
                      <h4 className="p-0 m-0 pt-2" style={{ color: "red" }}>
                        No slots available
                      </h4>
                    </div>
                  </div>
                </div> } */}
                {
                  slotIntervals.map((slot) => (
                    <div className={style.div_inner_flex} key={slot.id}>
                      <div
                        className={`${slotsTime === slot.id && isToday == false
                            ? `${style.card_selected}`
                            : `${style.card_static}`
                          }`}
                        onClick={() => cardClick(slot.id, "tomorrow")}
                      >
                        <div className="doc-info-center text-center ">
                          <h4 className="p-0 m-0">
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "var(--primary-color)",
                              }}
                            >
                              Book{" "}
                            </span>
                          </h4>
                        </div>

                        <img
                          src={slot.imageSrc}
                          alt=""
                          className={style.productImg}
                        />
                        <div className="doc-info-center text-center">
                          <h4
                            className="p-0 m-0"
                            style={{
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {slot.label}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))
                }
                  </>

                  
                )}

              </div>
            </div>
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
          </div>

          {/* <div className="row">
                        <div className="col-12">
                            <div className="row text-left">
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                                <div className="col-sm-10 col-md-10 col-xl-8">
                                    <div className={style.div_flex}>

                                        {!slots && todayDate === moment().format('dddd, MMM DD, YYYY') && slotsToShowTime.map(slot => (
                                            <div className={style.div_inner_flex} key={slot.id}>
                                                <div
                                                    className={`${slotsTime === slot.id ? `${style.card_selected}` : `${style.card_static}`}`}
                                                    onClick={() => cardClick(slot.id)}
                                                >
                                                    <img src={slot.imageSrc} alt="" className={style.productImg} />
                                                    <div className="doc-info-center text-center">
                                                        <h4 className="p-0 m-0">{slot.label}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {(slots || slotsToShowTime.length == 0) && todayDate == moment().format('dddd, MMM DD, YYYY')  && (
                                            <div className={style.div_inner_flex}>
                                                <div className={style.card_static}>
                                                    <img src="Images/problems/cancel.png" alt="" className={style.productImg} />
                                                    <div className="doc-info-center text-center">
                                                        <h4 className="p-0 m-0 pt-2">No slots available</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {todayDate !== moment().format('dddd, MMM DD, YYYY') && slotIntervals.map(slot => (
                                            <div className={style.div_inner_flex} key={slot.id}>
                                                <div
                                                    className={`${slotsTime === slot.id ? `${style.card_selected}` : `${style.card_static}`}`}
                                                    onClick={() => cardClick(slot.id)}
                                                >
                                                    <img src={slot.imageSrc} alt="" className={style.productImg} />
                                                    <div className="doc-info-center text-center">
                                                        <h4 className="p-0 m-0">{slot.label}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                            </div>
                        </div>
                    </div> */}
          <div className={style.reasonDiv}>
            <div className={``} style={{ width: 580, marginRight: 26 }}>
              <div className="pb-0 mb-0" style={{ textAlign: "start" }}>
                <Form
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <h4 className="card-title">Select Service *</h4>
                  <Select
                    showSearch
                    placeholder="Select Service"
                    optionFilterProp="children"
                    onChange={handleChangeService}
                    value={selectedServiceValue}
                    style={{
                      fontSize: 15,
                      width: "100%",
                      borderWidth: 1,
                      borderColor: "var(--primary-color)",
                      borderRadius: 5,
                      marginRight: 15,
                      marginBottom: 25,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {allServices?.map((itemm, index) => (
                      <>
                        {itemm.service != null ? (
                          <Option key={index} value={itemm.id}>
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {itemm.service} - ${itemm?.fees}
                            </span>
                          </Option>
                        ) : null}
                      </>
                    ))}

                    {/* // <Option value={'General Service'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         General Service - 10
                                            //     </span>
                                            // </Option>
                                            // <Option value={'Mental Health'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         Mental Health - 20
                                            //     </span>
                                            // </Option>
                                            // <Option value={'Sick Notes'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         Sick Notes and Forms - 30
                                            //     </span>
                                            // </Option> */}
                  </Select>
                  <h4 className="card-title">Vitals Notes *</h4>
                  <TextArea
      showCount
      maxLength={100}
      onChange={onChange}
      placeholder="Add Vitals"
      style={{ height: 150, resize: 'none', width: "100%", borderRadius: '100%' }}
    />

                </Form>
              </div>
            </div>
            
          </div>
         

          {/* <div className="row pb-5 mt-5"> */}
           
          {/* </div> */}

          {/* <div className={style.reasonDiv}>
            {slots !== true ? (
              <div className={``} style={{ width: 580, marginRight: 26 }}>
                <div className="pb-0 mb-0" style={{ textAlign: "start" }}>
                  <Form
                    layout="vertical"
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <h4 className="card-title">Select Service *</h4>
                    <Select
                      showSearch
                      placeholder="Select Service"
                      optionFilterProp="children"
                      onChange={handleChangeService}
                      value={selectedServiceValue}
                      style={{
                        fontSize: 15,
                        width: "100%",
                        borderWidth: 1,
                        borderColor: "var(--primary-color)",
                        borderRadius: 5,
                        marginRight: 15,
                        marginBottom: 25,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {allServices?.map((itemm, index) => (
                        <>
                          {itemm.service != null ? (
                            <Option key={index} value={itemm.id}>
                              <span style={{ whiteSpace: "pre-wrap" }}>
                                {itemm.service} - ${itemm?.fees}
                              </span>
                            </Option>
                          ) : null}
                        </>
                      ))} */}

          {/* // <Option value={'General Service'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         General Service - 10
                                            //     </span>
                                            // </Option>
                                            // <Option value={'Mental Health'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         Mental Health - 20
                                            //     </span>
                                            // </Option>
                                            // <Option value={'Sick Notes'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         Sick Notes and Forms - 30
                                            //     </span>
                                            // </Option> */}
          {/* </Select>
                  </Form>
                </div>
              </div>
            ) : null}
          </div> */}
        </div>
      </div>

      <div className="footer">
        <Footer
          location="paymentSummary"
          pageName="selectPrivateService"
          hide="true"
          valueSelectedServiceValue={selectedServiceValue}
          selectedServiceData={selectedService}
          appoimentDate={isToday == true ? todayDate : tomorrowDate}
          ValueForVitalText = {vitalTextValue}
          // disbleFooter={!(!slots && selectedCard !== "" && (selectedServiceValue === null || selectedServiceValue === ''))}
          disbleFooter={slotsCard && selectedService && addText ? true : false}
          slotsTime={slotsTime}
          selectslot={selectedCard}
        />
      </div>
      {/* {!slots ? (
        <div className="footer">
          <Footer
            location="paymentSummary"
            pageName="selectPrivateService"
            hide="true"
            valueSelectedServiceValue={selectedServiceValue}
            selectedServiceData={selectedService}
            appoimentDate={isToday == true ? todayDate : tomorrowDate}
            // disbleFooter={!(!slots && selectedCard !== "" && (selectedServiceValue === null || selectedServiceValue === ''))}
            disbleFooter={slotsCard && selectedService ? true : false}
            slotsTime={slotsTime}
            selectslot={selectedCard}
          />
        </div>
      ) : null} */}
    </div>
  );
};

export default SelectPrivateService;
