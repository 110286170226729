import React from 'react'
import style from "./style.module.scss"
import { Link } from 'App'
import { Breadcrumb } from 'react-bootstrap'

const PageHeader = (props:any) => {
  return (
    <div className="row mt-2 mb-3">
    <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="d-sm-block">
        <div className={style.breadcrumbDiv}>
          <h5 className={style.h5New}>
            <Breadcrumb >
            {props.items.map((item:any, index:any) => (
              <Breadcrumb.Item
                key={index}
                className="tx-medium"
                style={{fontSize:18}}
                href={item.link} 
                active={index === props.items.length - 1}>
                {item.label}
              </Breadcrumb.Item>
            ))}
            </Breadcrumb>
          </h5>
        </div>
        <div className={style.headerTop}>{props.pageTitle}</div>
      </div>
    </div>
    <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
  </div>
  )
}

export default PageHeader