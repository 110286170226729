import { faEquals } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TopBar } from "components";
import { useState } from "react";

import style from "./style.module.scss";


const TermsandConditions = () => {
  return (
    <div className='pb-5'>
      {/* <TopBar /> */}
      <div className="box">
        <div>
          <p className="pageInfoM">Terms and Conditions</p>
          <h5 className="h5NewM">Home / Terms and Conditions</h5>
        </div>
      </div>
      <div className="content" style={{ minHeight: 365 }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="terms-content">
                <div className="terms-text">
                  <h3>Etiam blandit lacus</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip.
                  </p>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
                <div className="terms-text">
                  <h4>Etiam sed fermentum lectus. Quisque vitae ipsum libero</h4>
                  <p>
                    Phasellus sit amet vehicula arcu. Etiam pulvinar dui libero, vitae
                    fringilla nulla convallis in. Fusce sagittis cursus nisl, at
                    consectetur elit vestibulum vestibulum:
                  </p>
                  <ul>
                    <li>Nunc pulvinar efficitur interdum.</li>
                    <li>Donec feugiat feugiat pulvinar.</li>
                    <li>
                      Suspendisse eu risus feugiat, pellentesque arcu eu, molestie
                      lorem.{" "}
                    </li>
                    <li>Duis non leo commodo, euismod ipsum a, feugiat libero.</li>
                  </ul>
                </div>
                <div className="terms-text">
                  <h3>Etiam blandit lacus</h3>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
                <div className="terms-text">
                  <h3>Maecenas sit amet</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default TermsandConditions;
