import { ActionTypes } from "../constants/action-types";
const intialState = {
  chathead: "",
  chatuser:'',
  currentchat : null
};

export const chat = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.CHATHEAD:
      return { ...state, chathead: payload };
  case ActionTypes.CHAT_USER:
      return { ...state, chatuser: payload };
  case ActionTypes.CURRENT_CHAT:
      return { ...state, currentchat: payload };
    default:
      return state;
  }
};
